import { now } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static values = {
    count: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 3000,
    },
    durationIsPerWholeNumber: {
      type: Boolean,
      default: true,
    },
    defaultStep: {
      type: Number,
      default: 1,
    },
    minIncreaseDelay: {
      type: Number,
      default: 25,
    },
    animationStartVisibilityThreshold: {
      type: Number,
      default: 0.1,
    },
    animationStartRootMargin: {
      type: Number,
      value: 0,
    },
  };

  initialize() {
    this.lastIncreaseAt = 0;
    this.observer = new IntersectionObserver(
      this.intersectionObserverCallback.bind(this),
      this.intersectionObserverOptions,
    );
  }

  connect() {
    this.currentValue = 0;
    this.element.textContent = this.currentValue;

    if (this.isTurboPreview) return;

    this.lastIncreaseAt = now();
    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
  }

  increase() {
    if (now() - this.lastIncreaseAt < this.increaseDelay) {
      requestAnimationFrame(this.increase.bind(this));
      return;
    }

    if (this.countValue <= this.currentValue) return;

    this.currentValue = Math.min(this.countValue, this.currentValue + this.increaseStep);
    this.element.textContent = this.currentValue.toLocaleString(gon.locale);

    this.lastIncreaseAt = now();
    requestAnimationFrame(this.increase.bind(this));
  }

  intersectionObserverCallback(entries, observer) {
    for (const { intersectionRatio, target } of entries) {
      if (intersectionRatio < this.animationStartVisibilityThresholdValue) continue;

      this.increase();
      observer.unobserve(target);
    }
  }

  get intersectionObserverOptions() {
    return {
      threshold: this.animationStartVisibilityThresholdValue,
      rootMargin: `${this.animationStartRootMarginValue}px`,
    };
  }

  get increaseStep() {
    if (!this.durationIsPerWholeNumberValue) {
      return this.defaultStepValue;
    }

    let step = this.defaultStepValue;

    while (this.durationValue / (this.countValue / step) < this.minIncreaseDelayValue) {
      step++;
    }

    return step;
  }

  get increaseDelay() {
    if (!this.durationIsPerWholeNumberValue) {
      return this.durationValue;
    }

    return this.durationValue / (this.countValue / this.increaseStep);
  }
}
