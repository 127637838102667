import useFloating from '@/fuse/javascript/composables/use_floating';
import useStore from '@/fuse/javascript/composables/use_store';
import { capitalizeFirstLetter, pad, resolveTwClasses } from '@slideslive/fuse-kit/utils';
import { de as deChrono, en as enChrono } from 'chrono-node';
import FormFieldController from 'fuse/form_field_component/form_field_component';
import { DateTime, Info, Interval } from 'luxon';

const lastInputNumberValue = (target, fallback) => {
  const lastInput = Number(target.dataset.lastInput);
  if (Number.isFinite(lastInput)) return lastInput;

  return fallback;
};

export default class extends FormFieldController {
  static get targets() {
    return [
      ...super.targets,
      'trigger',
      'originalInput',
      'calendar',
      'alternativeInput',
      'calendarTemplate',
      'calendarAlternativeInputTemplate',
      'monthPicker',
      'yearPicker',
      'monthButton',
      'yearButton',
      'month',
      'year',
      'weekday',
      'day',
      'dayButton',
      'timeContainer',
      'hourInput',
      'minuteInput',
      'secondInput',
      'meridiemInput',
      'timezone',
      'timezoneSelect',
      'specificDateTimeContainerTemplate',
      'specificDateTimeTemplate',
      'specificDateTimeSecondTemplate',
      'specificDateTimeMeridiemTemplate',
      'specificDateTime',
      'specificDateTimeLabel',
      'subCalendarCtas',
      'timeContainerCtas',
      'clearButton',
    ];
  }

  static get classes() {
    return [
      ...super.classes,
      'otherMonthButton',
      'todayButton',
      'selectedButton',
      'inRange',
      'startRange',
      'endRange',
      'weekendDay',
    ];
  }

  static get values() {
    return {
      ...super.values,
      skipHeaderPadding: {
        type: Boolean,
        default: false,
      },
      allowInput: {
        type: Boolean,
        default: true,
      },
      enableTime: {
        type: Boolean,
        default: true,
      },
      enableSeconds: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      futureOnly: {
        type: Boolean,
        default: false,
      },
      minDate: {
        type: String,
        default: null,
      },
      maxDate: {
        type: String,
        default: null,
      },
      disableTimezones: {
        type: Boolean,
        default: false,
      },
      adjustTimeAcrossTimezones: {
        type: Boolean,
        default: true,
      },
      altFormat: {
        type: String,
        default: null,
      },
      altTimezone: {
        type: String,
        default: null,
      },
      range: {
        type: Boolean,
        default: false,
      },
      useSeparateInputsForRange: {
        type: Boolean,
        default: true,
      },
      month: {
        type: Number,
        default: null,
      },
    };
  }

  initialize() {
    super.initialize();

    this.userInputTarget = null;
    this.resultInputTarget = null;
    this.originalDayClasses = '';
    this.originalDayButtonClasses = '';
    this.is24HourFormat = false;
    this.timeIds = [];

    this.state = useStore(this, {
      reducer: this.stateReducer,
      initialState: {
        hoveredDate: null,
        proposedDate: null,
        month: 0,
        yearRange: 0,
        lastDispatchedValue: '',
      },
    });

    this.state.onChange(this.state.PROPOSED_DATE, this.onProposedDateChange);
    this.state.onChange(this.state.HOVERED_DATE, this.onHoveredDateChange);
    this.state.onChange(this.state.MONTH, this.onMonthChange);
    this.state.onChange(this.state.YEAR_RANGE, this.onYearRangeChange);

    this.floating = useFloating(this, this.triggerTarget, null, {
      useHide: false,
      shiftOptions: { crossAxis: true },
      detectOverflowOptions: { rootBoundary: 'document' },
    });

    this.floating.on('open', this.onOpen.bind(this));
    this.floating.on('close', this.onClose.bind(this));
  }

  connect() {
    super.connect();

    this.floating.set({
      offset: 5,
      placement: 'bottom-start',
      skipHeaderPadding: this.skipHeaderPaddingValue,
    });
    this.floating.updateHeaderHeight();

    if (!this.hasCalendarTarget) {
      this.element.insertAdjacentHTML('beforeend', this.calendarTemplateTarget.innerHTML);
    } else {
      this.floating.set({ content: this.calendarTarget });
    }

    this.element.classList.toggle('range', this.rangeValue);

    this.userInputTarget = this.originalInputTarget;
    this.originalDayClasses = this.dayTargets[0].className;
    this.originalDayButtonClasses = this.dayButtonTargets[0].className;
    this.is24HourFormat = DateTime.now().toLocaleString({ hour: 'numeric' }).split(' ').length === 1;

    this.originalInputTarget.readOnly = !this.allowInputValue;
    this.timeContainerTarget.hidden = !this.enableTimeValue;
    this.timezoneTarget.hidden = this.disableTimezonesValue;
    this.subCalendarCtasTarget.hidden = this.enableTimeValue;

    this.initializeResultInput();
    this.initializeLocalizedElements();
    this.addTimeInputs();
    this.initializeTimeInputsValues();
    this.updateTimeLabels();
    this.updateTimeDisabled();

    if (this.userInputTarget.value.trim() !== '') {
      this.parseDate();
      this.state.dispatch('dispatched');
    }

    this.clearButtonTarget.hidden = !this.clearableValue || !this.state.proposedDate;

    this.initializeAltInput();
  }

  disconnect() {
    this.destroy();

    super.disconnect();
  }

  destroy() {
    this.state.reset({ skipChangeCallback: true });
    this.floating.destroy();
    this.floating.set({ content: null });

    if (this.hasCalendarTarget) {
      this.calendarTarget.remove();
    }

    if (this.hasAlternativeInputTarget) {
      this.originalInputTarget.removeAttribute('tabindex');
      this.originalInputTarget.id = this.userInputTarget.id;
      this.userInputTarget.remove();
    }

    if (this.hasSpecificDateTimeContainerTarget) {
      for (const target of this.specificDateTimeContainerTargets) {
        target.remove();
      }
    }

    if (this.hasOriginalInputTarget) {
      this.originalInputTarget.style.removeProperty('display');
    }

    if (this.resultInputTarget?.end) {
      this.resultInputTarget.end.remove();
    }

    this.resultInputTarget = null;
    this.originalDayClasses = '';
    this.originalDayButtonClasses = '';
    this.is24HourFormat = false;
    this.timeIds = [];
  }

  initializeAltInput() {
    if (this.hasAlternativeInputTarget) return;

    this.originalInputTarget.insertAdjacentHTML('beforebegin', this.calendarAlternativeInputTemplateTarget.innerHTML);

    this.alternativeInputTarget.id = this.originalInputTarget.id;
    this.alternativeInputTarget.className = this.originalInputTarget.className;
    this.alternativeInputTarget.value = this.originalInputTarget.value;
    this.alternativeInputTarget.placeholder = this.originalInputTarget.placeholder;
    this.alternativeInputTarget.readOnly = this.originalInputTarget.readOnly;
    this.alternativeInputTarget.disabled = this.originalInputTarget.disabled;

    this.originalInputTarget.removeAttribute('id');
    this.originalInputTarget.style.display = 'none';
    this.originalInputTarget.tabIndex = -1;

    this.alternativeInputTarget.hidden = false;

    this.userInputTarget = this.alternativeInputTarget;

    this.updateDateInput();
  }

  initializeResultInput() {
    if (this.useSeparateInputsForRangeValue && this.rangeValue) {
      const firstNonBracketCharIndexFromTheEnd = this.originalInputTarget.name
        .split('')
        .reverse()
        .findIndex((char) => ![']', '['].includes(char));
      let startName = this.originalInputTarget.name;
      let endName = this.originalInputTarget.name;

      if (firstNonBracketCharIndexFromTheEnd > 0) {
        const firstPart = this.originalInputTarget.name.slice(0, -1 * firstNonBracketCharIndexFromTheEnd);
        const lastPart = this.originalInputTarget.name.slice(-1 * firstNonBracketCharIndexFromTheEnd);

        startName = `${firstPart}_start${lastPart}`;
        endName = `${firstPart}_end${lastPart}`;
      } else {
        startName += '_start';
        endName += '_end';
      }

      const startRangeInput = document.createElement('input');
      const endRangeInput = document.createElement('input');
      startRangeInput.type = 'hidden';
      endRangeInput.type = 'hidden';
      startRangeInput.name = startName;
      endRangeInput.name = endName;

      this.originalInputTarget.insertAdjacentElement('afterend', endRangeInput);
      this.originalInputTarget.insertAdjacentElement('afterend', startRangeInput);

      this.resultInputTarget = {
        start: startRangeInput,
        end: endRangeInput,
      };
    } else {
      this.resultInputTarget = this.originalInputTarget;
    }
  }

  initializeLocalizedElements() {
    const months = Info.months('long', { locale: this.locale });
    const abbrMonths = Info.months('short', { locale: this.locale });
    const abbrWeekdays = Info.weekdays('short', { locale: this.locale });

    for (let i = 0; i < this.monthButtonTargets.length; i++) {
      this.monthButtonTargets[i].textContent = capitalizeFirstLetter(abbrMonths[i]);
      this.monthButtonTargets[i].dataset.monthName = capitalizeFirstLetter(months[i]);
    }

    for (let i = 0; i < this.weekdayTargets.length; i++) {
      const weekdayAbbrNameIndex = (this.firstDayOfWeekIndex + i) % 7;
      this.weekdayTargets[i].textContent = capitalizeFirstLetter(abbrWeekdays[weekdayAbbrNameIndex]);

      if (this.weekendWeekdaysIndexes.includes(i)) {
        this.weekdayTargets[i].classList.add('tw-opacity-68');
      }
    }

    if (this.enableTimeValue) {
      for (const timezoneOption of this.timezoneSelectTarget.options) {
        if (timezoneOption.disabled) continue;

        let timezoneOffset = DateTime.now().setZone(timezoneOption.value).toFormat('ZZZ');
        timezoneOffset = `${timezoneOffset.slice(0, -2)}:${timezoneOffset.slice(-2)}`;

        timezoneOption.textContent = timezoneOption.textContent.replace(/\([^)]*\)/, `(GMT${timezoneOffset})`);
      }
    }
  }

  initializeTimeInputsValues() {
    if (!this.enableTimeValue) return;

    const now = DateTime.now();

    for (const target of this.hourInputTargets) {
      target.dataset.in24Hour = now.hour;
      target.value = this.formattedHourForDate(now);
    }

    for (const target of this.minuteInputTargets) {
      target.value = pad(now.minute);
    }

    for (const target of this.secondInputTargets) {
      target.value = pad(now.second);
    }

    this.timezoneSelectTarget.value = this.altTimezoneValue || now.zoneName;
  }

  calendarTargetConnected(element) {
    this.floating.set({ content: element });
  }

  handleConstraintsChanged() {
    if (this.floating.opened()) {
      this.renderCalendar();
    }
  }

  minDateValueChanged(newValue) {
    if (!newValue) return this.handleConstraintsChanged();
    if (!this.state.proposedDate) return this.handleConstraintsChanged();

    if (Interval.isInterval(this.state.proposedDate)) {
      if (this.isValidDatetime(this.state.proposedDate.start)) return this.handleConstraintsChanged();
    } else if (this.isValidDatetime(this.state.proposedDate)) return this.handleConstraintsChanged();

    this.state.dispatch('date/propose', null);
    this.state.dispatch('month/current');
    this.updateDateInput();

    return undefined;
  }

  maxDateValueChanged(newValue) {
    if (!newValue) return this.handleConstraintsChanged();
    if (!this.state.proposedDate) return this.handleConstraintsChanged();

    if (Interval.isInterval(this.state.proposedDate)) {
      if (this.isValidDatetime(this.state.proposedDate.end)) return this.handleConstraintsChanged();
    } else if (this.isValidDatetime(this.state.proposedDate)) return this.handleConstraintsChanged();

    this.state.dispatch('date/propose', null);
    this.state.dispatch('month/current');
    this.updateDateInput();

    return undefined;
  }

  monthValueChanged(newValue) {
    if (newValue === this.state.month) return;

    this.state.dispatch('month/set', newValue);
  }

  addTimeInputsSingleMode() {
    const timeId = Math.round(Math.random() * 1000000000).toString();
    const specificDateTimeContainerHTML = this.specificDateTimeContainerTemplateTarget.innerHTML;
    const specificDateTimeHTML = this.specificDateTimeTemplateTarget.innerHTML
      .replace(
        '{SPECIFIC_DATE_TIME_SECOND}',
        this.enableSecondsValue ? this.specificDateTimeSecondTemplateTarget.innerHTML : '',
      )
      .replace(
        '{SPECIFIC_DATE_TIME_MERIDIEM}',
        this.is24HourFormat ? '' : this.specificDateTimeMeridiemTemplateTarget.innerHTML,
      );

    const html = specificDateTimeContainerHTML.replace('{CONTENT}', specificDateTimeHTML).replace(/{TIME_ID}/g, timeId);

    this.timeContainerTarget.insertAdjacentHTML('afterbegin', html);
    this.timeIds.push({ timeId, dateISO: '' });
  }

  addTimeInputsRangeMode() {
    const startTimeId = Math.round(Math.random() * 1000000000).toString();
    const endTimeId = Math.round(Math.random() * 1000000000).toString();
    const specificDateTimeContainerHTML = this.specificDateTimeContainerTemplateTarget.innerHTML;
    const specificDateTimeHTML = this.specificDateTimeTemplateTarget.innerHTML
      .replace(
        '{SPECIFIC_DATE_TIME_SECOND}',
        this.enableSecondsValue ? this.specificDateTimeSecondTemplateTarget.innerHTML : '',
      )
      .replace(
        '{SPECIFIC_DATE_TIME_MERIDIEM}',
        this.is24HourFormat ? '' : this.specificDateTimeMeridiemTemplateTarget.innerHTML,
      );
    const specifiedDateStartTimeHTML = specificDateTimeHTML.replace(/{TIME_ID}/g, startTimeId);
    const specifiedDateEndTimeHTML = specificDateTimeHTML.replace(/{TIME_ID}/g, endTimeId);
    const html = specificDateTimeContainerHTML
      .replace('{CONTENT}', `${specifiedDateStartTimeHTML}${specifiedDateEndTimeHTML}`)
      .replace(/{TIME_ID}/g, startTimeId);

    this.timeContainerTarget.insertAdjacentHTML('afterbegin', html);
    this.timeIds.push({ start: { timeId: startTimeId, dateISO: '' }, end: { timeId: endTimeId, dateISO: '' } });
  }

  addTimeInputs() {
    if (this.rangeValue) {
      this.addTimeInputsRangeMode();
    } else {
      this.addTimeInputsSingleMode();
    }
  }

  onProposedDateChange(newValue, oldValue) {
    if (!newValue || !oldValue) {
      this.renderCalendar();
    } else if (
      DateTime.isDateTime(newValue) !== DateTime.isDateTime(oldValue) ||
      newValue.toISODate() !== oldValue.toISODate()
    ) {
      this.renderCalendar();
    }

    this.updateTimeLabels();
    this.updateTimeDisabled();
  }

  onHoveredDateChange() {
    if (Interval.isInterval(this.state.proposedDate)) return;

    this.renderCalendar();
  }

  onMonthChange() {
    this.renderCalendar();
  }

  onYearRangeChange() {
    this.renderYearPicker();
  }

  onOpen() {
    if (!this.hasCalendarTarget) return;

    this.calendarTarget.hidden = false;
  }

  onClose() {
    if (!this.hasCalendarTarget) return;

    this.calendarTarget.hidden = true;
    this.hideMonthPicker();
    this.hideYearPicker();
  }

  open(event) {
    if (this.floating.opened()) return;
    if (event?.target === this.clearButtonTarget) return;

    if (!this.state.proposedDate) {
      this.renderCalendar();
    }

    this.floating.open();
  }

  closeFromEvent({ target }) {
    if (!this.floating.opened()) return;
    if (this.element === target || this.element.contains(target)) return;
    if (
      document.activeElement &&
      (this.element === document.activeElement || this.element.contains(document.activeElement))
    ) {
      return;
    }

    this.confirm();
  }

  closeOnEscape(event) {
    if (!this.floating.opened()) return;

    event.preventDefault();
    event.stopPropagation();

    this.confirm();
  }

  toggleInputAllowanceByPointerType({ pointerType }) {
    if (!this.hasAlternativeInputTarget) return;

    this.alternativeInputTarget.readOnly = !this.allowInputValue || ['touch', 'pen'].includes(pointerType);
  }

  showYearPicker() {
    this.currentYearRange();
    this.yearPickerTarget.hidden = false;
  }

  showMonthPicker() {
    this.monthPickerTarget.hidden = false;
  }

  hideYearPicker() {
    this.yearPickerTarget.hidden = true;
  }

  hideMonthPicker() {
    this.monthPickerTarget.hidden = true;
  }

  currentMonth() {
    this.state.dispatch('month/current');
  }

  nextMonth() {
    this.state.dispatch('month/next');
  }

  previousMonth() {
    this.state.dispatch('month/previous');
  }

  currentYearRange() {
    this.state.dispatch('year-range/current');
  }

  nextYearRange() {
    this.state.dispatch('year-range/next');
  }

  previousYearRange() {
    this.state.dispatch('year-range/previous');
  }

  handlePickDateSingleMode(newProposedDate) {
    if (this.state.proposedDate?.hasSame(newProposedDate, 'day')) return;

    this.timeIds[0].dateISO = this.dateISO(newProposedDate);
    this.state.dispatch('date/propose', newProposedDate.set(this.timeForDate(newProposedDate)));

    if (!this.enableTimeValue) {
      this.confirm();
    } else {
      this.updateDateInput();

      if (this.hasHourInputTarget) this.hourInputTarget.focus();
    }
  }

  handlePickDateRangeMode(newProposedDate) {
    if (!this.state.proposedDate || Interval.isInterval(this.state.proposedDate)) {
      const dateISO = this.dateISO(newProposedDate);

      this.timeIds[0].dateISO = dateISO;
      this.timeIds[0].start.dateISO = dateISO;
      this.timeIds[0].end.dateISO = '';
      this.state.dispatch('date/propose', newProposedDate.set(this.timeForDate(newProposedDate).start));

      this.updateDateInput();
    } else if (DateTime.isDateTime(this.state.proposedDate)) {
      newProposedDate = Interval.fromDateTimes(...[this.state.proposedDate, newProposedDate].sort());

      this.timeIds[0].dateISO = this.dateISO(newProposedDate);
      this.timeIds[0].start.dateISO = this.dateISO(newProposedDate.start);
      this.timeIds[0].end.dateISO = this.dateISO(newProposedDate.end);

      const time = this.timeForDate(newProposedDate);
      const newProposedDateWithTime = Interval.fromDateTimes(
        ...[newProposedDate.start.set(time.start), newProposedDate.end.set(time.end)].sort(),
      );

      this.updateTimeInputsOrderRangeMode(this.timeIds[0].end.timeId, newProposedDateWithTime, newProposedDate);

      this.state.dispatch('date/propose', newProposedDateWithTime);

      if (!this.enableTimeValue) {
        this.confirm();
      } else {
        this.updateDateInput();
      }
    }
  }

  pickDate({ target }) {
    if (document.activeElement === target) {
      target.blur();
    }

    const newProposedDate = DateTime.fromISO(target.dataset.date).setZone(this.selectedTimezone, {
      keepLocalTime: true,
    });

    if (this.rangeValue) {
      this.handlePickDateRangeMode(newProposedDate);
    } else {
      this.handlePickDateSingleMode(newProposedDate);
    }
  }

  proposeHoveredDate({ target, pointerType }) {
    if (pointerType !== 'mouse') return;
    if (!this.rangeValue) return;
    if (!this.state.proposedDate || Interval.isInterval(this.state.proposedDate)) return;

    const newHoveredDate = DateTime.fromISO(target.dataset.date).setZone(this.selectedTimezone, {
      keepLocalTime: true,
    });

    if (this.state.proposedDate.hasSame(newHoveredDate, 'day')) return;

    this.state.dispatch('date/hover', newHoveredDate);
  }

  clearHoveredDate({ pointerType }) {
    if (pointerType !== 'mouse') return;
    if (!this.rangeValue) return;

    this.state.dispatch('date/hover', null);
  }

  pickMonth({ target }) {
    const parsedMonth = Number(target.dataset.month) + 1;

    const monthDifference = this.renderedMonth.month - parsedMonth;

    if (monthDifference !== 0) {
      this.state.month -= monthDifference;
    }

    this.hideMonthPicker();
  }

  pickYear({ target }) {
    const parsedYear = Number(target.dataset.year);

    const yearsDifference = parsedYear - this.renderedMonth.year;

    if (yearsDifference !== 0) {
      this.state.month += yearsDifference * 12;
    }

    this.showMonthPicker();
    this.hideYearPicker();
  }

  handleParseDateSingleMode(date) {
    this.timeIds[0].dateISO = this.dateISO(date[0]);
    this.state.dispatch('date/propose', date[0]);
  }

  handleParseDateRangeMode(date) {
    if (date.length === 1) {
      date = date[0];

      const dateISO = this.dateISO(date);

      this.timeIds[0].dateISO = dateISO;
      this.timeIds[0].start.dateISO = dateISO;
      this.timeIds[0].end.dateISO = '';
      this.state.dispatch('date/propose', date);

      return;
    }

    date = Interval.fromDateTimes(...date.sort());

    this.timeIds[0].dateISO = this.dateISO(date);
    this.timeIds[0].start.dateISO = this.dateISO(date.start);
    this.timeIds[0].end.dateISO = this.dateISO(date.end);
    this.state.dispatch('date/propose', date);
  }

  parseDate() {
    const chronos = [];
    const newProposedDates = [];
    let value = this.userInputTarget.value.trim();
    let parsedResults = null;

    if (value === '') {
      this.state.dispatch('date/propose', null);
      this.state.dispatch('month/current');

      return;
    }

    if (this.locale === 'de') {
      chronos.push(deChrono);
    }

    if (value.match(/\d+\.\s*/)) {
      value = value.replace(/(\d+)\.\s+/g, '$1.');
      chronos.push(enChrono.GB);
    } else {
      chronos.push(enChrono);
    }

    for (const chrono of chronos) {
      parsedResults = chrono.parse(value, { timezone: this.selectedTimezoneForChronoJs });

      if (parsedResults.length > 0) break;
    }

    if (parsedResults.length === 0) {
      this.state.dispatch('date/propose', null);
      this.state.dispatch('month/current');

      return;
    }

    for (const parsedResult of parsedResults) {
      if (this.enableTimeValue) {
        for (const endpoint of ['start', 'end']) {
          if (!parsedResult[endpoint]) continue;
          if (!('hour' in parsedResult[endpoint].impliedValues)) continue;

          const lastTime = this.timeForTimeId(
            this.rangeValue
              ? this.timeIds[this.timeIds.length - 1][endpoint].timeId
              : this.timeIds[this.timeIds.length - 1].timeId,
          );

          for (const valueKey of ['hour', 'minute', 'second']) {
            if (!(valueKey in parsedResult[endpoint].impliedValues)) continue;

            parsedResult[endpoint].impliedValues[valueKey] = lastTime[valueKey];
          }

          parsedResult[endpoint].impliedValues.millisecond = 0;
        }
      }

      let dates = [];

      if (parsedResult.start) {
        dates.push(parsedResult.start.date());
      }

      if (parsedResult.end) {
        dates.push(parsedResult.end.date());
      }

      dates = dates.map((date) => DateTime.fromJSDate(date).setZone(this.selectedTimezone));
      dates = dates.filter((date) => this.isValidDatetime(date));

      if (dates.length === 0) continue;

      newProposedDates.push(dates);
    }

    if (newProposedDates.length === 0) {
      this.state.dispatch('date/propose', null);
      this.state.dispatch('month/current');

      return;
    }

    if (this.rangeValue) {
      if (newProposedDates.length > 1 && newProposedDates[0].length === 1) {
        newProposedDates[0].push(newProposedDates[1][0]);
      }

      this.handleParseDateRangeMode(newProposedDates[0]);
    } else {
      this.handleParseDateSingleMode(newProposedDates[0]);
    }

    if (DateTime.isDateTime(this.state.proposedDate)) {
      const yearsDifference = this.state.proposedDate.year - this.renderedMonth.year;
      const monthsDifference = this.state.proposedDate.month - this.renderedMonth.month;

      this.state.month += monthsDifference + 12 * yearsDifference;
    } else if (Interval.isInterval(this.state.proposedDate)) {
      const yearsDifference = this.state.proposedDate.end.year - this.renderedMonth.year;
      const monthsDifference = this.state.proposedDate.end.month - this.renderedMonth.month;

      this.state.month += monthsDifference + 12 * yearsDifference;
    }

    this.updateTimeInputs();
  }

  fillInProposedDate() {
    if (this.floating.opened()) return;

    this.updateDateInput();
    this.fireChange();
  }

  updateTimeInputsOrderRangeMode(timeId, newProposedDate, proposedDate = this.state.proposedDate) {
    // Check if the order of the datetimes in the interval changed

    if (!Interval.isInterval(proposedDate) || !Interval.isInterval(newProposedDate)) return;
    if (!newProposedDate.start.hasSame(newProposedDate.end, 'day')) return;

    const timeIdObject = this.timeIds.find((it) => it.start.timeId === timeId || it.end.timeId === timeId);
    const endpoint = timeIdObject.start.timeId === timeId ? 'start' : 'end';
    const otherEndpoint = endpoint === 'start' ? 'end' : 'start';

    if (newProposedDate[otherEndpoint] === proposedDate[otherEndpoint]) return;

    const startTimeInputs = this.specificDateTimeTargets.find((it) => it.dataset.timeId === timeIdObject.start.timeId);
    const endTimeInputs = this.specificDateTimeTargets.find((it) => it.dataset.timeId === timeIdObject.end.timeId);

    // Move the other time inputs so user does not lose focus
    if (endpoint === 'start') {
      startTimeInputs.insertAdjacentElement('beforebegin', endTimeInputs);
    } else {
      endTimeInputs.insertAdjacentElement('afterend', startTimeInputs);
    }

    timeIdObject.start.timeId = endTimeInputs.dataset.timeId;
    timeIdObject.end.timeId = startTimeInputs.dataset.timeId;
  }

  handleAdjustTimeSingleMode(target) {
    const time = this.timeForTimeId(target.dataset.timeId);

    this.state.dispatch('date/propose', this.state.proposedDate.set(time));
  }

  handleAdjustTimeRangeMode(target) {
    if (DateTime.isDateTime(this.state.proposedDate)) {
      this.handleAdjustTimeSingleMode(target);

      return;
    }

    const timeId = target.dataset.timeId;
    const timeIdObject = this.timeIds.find((it) => it.start.timeId === timeId || it.end.timeId === timeId);
    const endpoint = timeIdObject.start.timeId === timeId ? 'start' : 'end';
    const otherEndpoint = endpoint === 'start' ? 'end' : 'start';
    const time = this.timeForTimeId(timeId);
    const newProposedDate = Interval.fromDateTimes(
      ...[this.state.proposedDate[endpoint].set(time), this.state.proposedDate[otherEndpoint]].sort(),
    );

    this.updateTimeInputsOrderRangeMode(timeId, newProposedDate);

    this.state.dispatch('date/propose', newProposedDate);
  }

  adjustTime(target) {
    if (!this.state.proposedDate) return;

    if (this.rangeValue) {
      this.handleAdjustTimeRangeMode(target);
    } else {
      this.handleAdjustTimeSingleMode(target);
    }

    this.updateDateInput();
  }

  saveLastInput({ target, data }) {
    target.dataset.lastInput = data;
  }

  updateTimeHour({ target }) {
    let value = Number(target.value);
    let in24HourValue = value;

    if (!this.is24HourFormat) {
      const timeId = target.dataset.timeId;
      const amInputTarget = this.meridiemInputTargets.find((t) => t.dataset.timeId === timeId && t.value === 'am');

      if (value > 12) value = lastInputNumberValue(target, 1);
      if (value < 1) value = lastInputNumberValue(target, 12);

      if (amInputTarget.checked) {
        if (value === 12) {
          in24HourValue = 0;
        } else {
          in24HourValue = value;
        }
      } else if (value === 12) {
        in24HourValue = value;
      } else {
        in24HourValue = value + 12;
      }
    } else if (value < 0) {
      in24HourValue = 23;
      value = lastInputNumberValue(target, in24HourValue);
    } else if (value > 23) {
      in24HourValue = 0;
      value = lastInputNumberValue(target, in24HourValue);
    }

    target.dataset.lastInput = undefined;
    target.dataset.in24Hour = in24HourValue;
    target.value = this.formattedHourForDate(DateTime.now().set({ hour: value }));

    this.adjustTime(target);
  }

  updateTimeMinuteSecond({ target }) {
    let value = Number(target.value);

    if (value < 0) {
      value = lastInputNumberValue(target, 59);
    } else if (value > 59) {
      value = lastInputNumberValue(target, 0);
    }

    target.dataset.lastInput = undefined;
    target.value = pad(value);

    this.adjustTime(target);
  }

  handleChangeMeridiemSingleMode(target) {
    const hoursChange = target.value === 'am' ? -12 : 12;

    this.state.dispatch('date/propose', this.state.proposedDate.plus({ hour: hoursChange }));
  }

  handleChangeMeridiemRangeMode(target) {
    if (DateTime.isDateTime(this.state.proposedDate)) {
      this.handleChangeMeridiemSingleMode(target);

      return;
    }

    const {
      dataset: { timeId },
    } = target;
    const hoursChange = target.value === 'am' ? -12 : 12;
    const timeIdObject = this.timeIds.find((it) => it.start.timeId === timeId || it.end.timeId === timeId);
    const endpoint = timeIdObject.start.timeId === timeId ? 'start' : 'end';
    const otherEndpoint = endpoint === 'start' ? 'end' : 'start';

    this.state.dispatch(
      'date/propose',
      Interval.fromDateTimes(
        ...[
          this.state.proposedDate[endpoint].plus({ hour: hoursChange }),
          this.state.proposedDate[otherEndpoint],
        ].sort(),
      ),
    );
  }

  changeMeridiem({ target }) {
    if (this.rangeValue) {
      this.handleChangeMeridiemRangeMode(target);
    } else {
      this.handleChangeMeridiemSingleMode(target);
    }

    this.updateDateInput();
    this.updateMeridiem(target);
  }

  handleAdjustTimezoneSingleMode() {
    if (this.adjustTimeAcrossTimezonesValue) {
      this.state.dispatch(
        'date/propose',
        this.state.proposedDate.setZone(this.selectedTimezone, {
          keepLocalTime: true,
        }),
      );
    } else {
      this.state.dispatch('date/propose', this.state.proposedDate.setZone(this.selectedTimezone));
    }
  }

  handleAdjustTimezoneRangeMode() {
    if (DateTime.isDateTime(this.state.proposedDate)) {
      this.handleAdjustTimezoneSingleMode();

      return;
    }

    let newProposedDate;

    if (this.adjustTimeAcrossTimezonesValue) {
      newProposedDate = this.state.proposedDate.mapEndpoints((date) =>
        date.setZone(this.selectedTimezone, {
          keepLocalTime: true,
        }),
      );
    } else {
      newProposedDate = this.state.proposedDate.mapEndpoints((date) => date.setZone(this.selectedTimezone));
    }

    this.state.dispatch('date/propose', newProposedDate);
  }

  adjustTimezone() {
    if (!this.state.proposedDate) return;

    if (this.rangeValue) {
      this.handleAdjustTimezoneRangeMode();
    } else {
      this.handleAdjustTimezoneSingleMode();
    }

    if (!this.adjustTimeAcrossTimezonesValue) {
      this.updateTimeInputs();
    }

    this.updateDateInput();
  }

  timeTargetBelongsToDateSingleMode({ dataset: { timeId } }) {
    for (const timeIdObject of this.timeIds) {
      if (timeIdObject.timeId === timeId) return !!timeIdObject.dateISO;
    }

    return false;
  }

  timeTargetBelongsToDateRangeMode({ dataset: { timeId } }) {
    for (const timeIdObject of this.timeIds) {
      if (timeIdObject.start.timeId === timeId) return !!timeIdObject.start.dateISO;
      if (timeIdObject.end.timeId === timeId) return !!timeIdObject.end.dateISO;
    }

    return false;
  }

  timeTargetBelongsToDate(target) {
    if (this.rangeValue) {
      return this.timeTargetBelongsToDateRangeMode(target);
    }

    return this.timeTargetBelongsToDateSingleMode(target);
  }

  updateTimeDisabled() {
    for (const target of [...this.hourInputTargets, ...this.minuteInputTargets]) {
      target.disabled = !this.state.proposedDate || !this.enableTimeValue || !this.timeTargetBelongsToDate(target);
    }

    for (const target of this.secondInputTargets) {
      target.disabled =
        !this.state.proposedDate ||
        !this.enableTimeValue ||
        !this.enableSecondsValue ||
        !this.timeTargetBelongsToDate(target);
    }

    for (const target of this.meridiemInputTargets) {
      this.updateMeridiem(target);
    }
  }

  handleUpdateMeridiemSingleMode(target) {
    const timeId = target.dataset.timeId;
    let isAm;

    if (this.state.proposedDate) {
      isAm = this.state.proposedDate.hour < 12;
    } else {
      const hourInputTarget = this.hourInputTargets.find((t) => t.dataset.timeId === timeId);

      isAm = Number(hourInputTarget.dataset.in24Hour) < 12;
    }

    if (isAm) {
      const amMeridiemInputTarget = this.meridiemInputTargets.find(
        (t) => t.dataset.timeId === timeId && t.value === 'am',
      );

      amMeridiemInputTarget.checked = true;
    } else {
      const pmMeridiemInputTarget = this.meridiemInputTargets.find(
        (t) => t.dataset.timeId === timeId && t.value === 'pm',
      );

      pmMeridiemInputTarget.checked = true;
    }
  }

  handleUpdateMeridiemRangeMode(target) {
    if (DateTime.isDateTime(this.state.proposedDate)) {
      this.handleUpdateMeridiemSingleMode(target);

      return;
    }

    const timeId = target.dataset.timeId;
    let isAm;

    if (this.state.proposedDate) {
      const timeIdObject = this.timeIds.find((it) => it.start.timeId === timeId || it.end.timeId === timeId);
      const endpoint = timeIdObject.start.timeId === timeId ? 'start' : 'end';

      isAm = this.state.proposedDate[endpoint].hour < 12;
    } else {
      const hourInputTarget = this.hourInputTargets.find((t) => t.dataset.timeId === timeId);

      isAm = Number(hourInputTarget.dataset.in24Hour) < 12;
    }

    if (isAm) {
      const amMeridiemInputTarget = this.meridiemInputTargets.find(
        (t) => t.dataset.timeId === timeId && t.value === 'am',
      );

      amMeridiemInputTarget.checked = true;
    } else {
      const pmMeridiemInputTarget = this.meridiemInputTargets.find(
        (t) => t.dataset.timeId === timeId && t.value === 'pm',
      );

      pmMeridiemInputTarget.checked = true;
    }
  }

  updateMeridiem(target) {
    if (this.is24HourFormat) return;

    const timeId = target.dataset.timeId;
    const amMeridiemInputTarget = this.meridiemInputTargets.find(
      (t) => t.dataset.timeId === timeId && t.value === 'am',
    );
    const pmMeridiemInputTarget = this.meridiemInputTargets.find(
      (t) => t.dataset.timeId === timeId && t.value === 'pm',
    );

    amMeridiemInputTarget.disabled =
      !this.state.proposedDate || !this.enableTimeValue || !this.timeTargetBelongsToDate(target);
    pmMeridiemInputTarget.disabled =
      !this.state.proposedDate || !this.enableTimeValue || !this.timeTargetBelongsToDate(target);

    if (this.rangeValue) {
      this.handleUpdateMeridiemRangeMode(target);
    } else {
      this.handleUpdateMeridiemSingleMode(target);
    }
  }

  updateTimeLabelsSingleMode() {
    for (let i = 0; i < this.specificDateTimeLabelTargets.length; i++) {
      const target = this.specificDateTimeLabelTargets[i];

      target.textContent = target.dataset.label;
    }
  }

  updateTimeLabelsRangeMode() {
    for (let i = 0; i < this.specificDateTimeLabelTargets.length; i++) {
      const target = this.specificDateTimeLabelTargets[i];
      const timeId = target.dataset.timeId;
      const timeIdObject = this.timeIds.find((it) => it.start.timeId === timeId || it.end.timeId === timeId);
      const endpoint = timeIdObject.start.timeId === timeId ? 'start' : 'end';
      const type = target.dataset[`label${capitalizeFirstLetter(endpoint)}`];

      if (timeIdObject[endpoint].dateISO !== '') {
        const dateTime = DateTime.fromISO(timeIdObject[endpoint].dateISO);

        target.textContent = `${type}: ${dateTime.toLocaleString(DateTime.DATE_SHORT, { locale: this.locale })}`;
      } else {
        target.textContent = type;
      }
    }
  }

  updateTimeLabels() {
    if (this.rangeValue) {
      this.updateTimeLabelsRangeMode();
    } else {
      this.updateTimeLabelsSingleMode();
    }
  }

  handleUpdateDateInputDataAttributesSingleMode(date) {
    if (this.enableTimeValue) {
      this.resultInputTarget.value = this.dateTimeISO(date);
    } else {
      this.resultInputTarget.value = this.dateISO(date);
    }

    this.resultInputTarget.dataset.enDate = this.resultInputTarget.value;
  }

  handleUpdateDateInputDataAttributesRangeMode(date) {
    if (this.enableTimeValue) {
      this.resultInputTarget.value = this.dateTimeISO(date);
    } else {
      this.resultInputTarget.value = this.dateISO(date);
    }

    if (DateTime.isDateTime(date)) {
      this.resultInputTarget.dataset.enDateStart = this.resultInputTarget.value;
      this.resultInputTarget.dataset.enDateEnd = '';
    } else if (this.enableTimeValue) {
      this.resultInputTarget.dataset.enDateStart = this.dateTimeISO(date.start);
      this.resultInputTarget.dataset.enDateEnd = this.dateTimeISO(date.end);
    } else {
      this.resultInputTarget.dataset.enDateStart = this.dateISO(date.start);
      this.resultInputTarget.dataset.enDateEnd = this.dateISO(date.end);
    }
  }

  handleUpdateDateInputSingleMode(date) {
    if (this.enableTimeValue) {
      this.resultInputTarget.value = this.dateTimeISO(date);
    } else {
      this.resultInputTarget.value = this.dateISO(date);
    }
  }

  handleUpdateDateInputRangeMode(date) {
    if (DateTime.isDateTime(date)) {
      if (this.enableTimeValue) {
        this.resultInputTarget.start.value = this.dateTimeISO(date);
      } else {
        this.resultInputTarget.start.value = this.dateISO(date);
      }

      this.resultInputTarget.end.value = '';
    } else if (this.enableTimeValue) {
      this.resultInputTarget.start.value = this.dateTimeISO(date.start);
      this.resultInputTarget.end.value = this.dateTimeISO(date.end);
    } else {
      this.resultInputTarget.start.value = this.dateISO(date.start);
      this.resultInputTarget.end.value = this.dateISO(date.end);
    }

    if (this.resultInputTarget.end.value === '') {
      this.originalInputTarget.value = `${this.resultInputTarget.start.value}`;
    } else {
      this.originalInputTarget.value = `${this.resultInputTarget.start.value}/${this.resultInputTarget.end.value}`;
    }

    this.originalInputTarget.dataset.enDateStart = this.resultInputTarget.start.value;
    this.originalInputTarget.dataset.enDateEnd = this.resultInputTarget.end.value;
  }

  updateDateInput() {
    let date = this.state.proposedDate;

    if (!date) {
      this.userInputTarget.value = '';
      this.resultInputTarget.value = '';

      if (this.useSeparateInputsForRangeValue && this.rangeValue) {
        this.resultInputTarget.start.value = '';
        this.resultInputTarget.end.value = '';
      }

      return;
    }

    if (!this.enableTimeValue) {
      if (DateTime.isDateTime(date)) {
        date = date.setZone('Etc/UTC', { keepLocalTime: true });
      } else if (Interval.isInterval(date)) {
        date = date.set({
          start: date.start.setZone('Etc/UTC', { keepLocalTime: true }).startOf('day'),
          end: date.end.setZone('Etc/UTC', { keepLocalTime: true }).endOf('day'),
        });
      }
    }

    if (this.hasAlternativeInputTarget) {
      if (this.altFormatValue) {
        this.userInputTarget.value = date.toFormat(this.altFormatValue, { locale: this.locale }) || '';
      } else {
        this.userInputTarget.value = date.toLocaleString(this.format, { locale: this.locale }) || '';
      }
    }

    if (DateTime.isDateTime(date)) {
      date = date.setZone('Etc/UTC');
    } else if (Interval.isInterval(date)) {
      if (!this.enableTimeValue) {
        date = date.set({
          start: date.start.startOf('day').setZone('Etc/UTC'),
          end: date.end.endOf('day').setZone('Etc/UTC'),
        });
      } else {
        date = date.mapEndpoints((d) => d.setZone('Etc/UTC'));
      }
    }

    if (!this.useSeparateInputsForRangeValue) {
      if (this.rangeValue) {
        this.handleUpdateDateInputDataAttributesRangeMode(date);
      } else {
        this.handleUpdateDateInputDataAttributesSingleMode(date);
      }
    } else if (this.rangeValue) {
      this.handleUpdateDateInputRangeMode(date);
    } else {
      this.handleUpdateDateInputSingleMode(date);
    }
  }

  updateTimeInputsSingleMode(date) {
    const dateISO = this.dateISO(date);
    const hourInputTarget = this.timeTargetByDateISOSingleMode(this.hourInputTargets, dateISO);
    const minuteInputTarget = this.timeTargetByDateISOSingleMode(this.minuteInputTargets, dateISO);
    const secondInputTarget = this.timeTargetByDateISOSingleMode(this.secondInputTargets, dateISO);

    hourInputTarget.dataset.in24Hour = date.hour;
    hourInputTarget.value = this.formattedHourForDate(date);
    minuteInputTarget.value = pad(date.minute);

    if (secondInputTarget) {
      secondInputTarget.value = pad(date.second);
    }
  }

  updateTimeInputsRangeMode(date) {
    const dateISO = this.dateISO(date);
    const hourInputTarget = this.timeTargetByDateISORangeMode(this.hourInputTargets, dateISO);
    const minuteInputTarget = this.timeTargetByDateISORangeMode(this.minuteInputTargets, dateISO);
    const secondInputTarget = this.timeTargetByDateISORangeMode(this.secondInputTargets, dateISO);

    if (DateTime.isDateTime(date)) {
      hourInputTarget.start.dataset.in24Hour = date.hour;
      hourInputTarget.start.value = this.formattedHourForDate(date);
      minuteInputTarget.start.value = pad(date.minute);

      if (secondInputTarget.start) {
        secondInputTarget.start.value = pad(date.second);
      }

      return;
    }

    hourInputTarget.start.dataset.in24Hour = date.start.hour;
    hourInputTarget.start.value = this.formattedHourForDate(date.start);
    hourInputTarget.end.dataset.in24Hour = date.end.hour;
    hourInputTarget.end.value = this.formattedHourForDate(date.end);
    minuteInputTarget.start.value = pad(date.start.minute);
    minuteInputTarget.end.value = pad(date.end.minute);

    if (secondInputTarget.start) {
      secondInputTarget.start.value = pad(date.start.second);
    }

    if (secondInputTarget.end) {
      secondInputTarget.end.value = pad(date.end.second);
    }
  }

  updateTimeInputs(date = this.state.proposedDate) {
    if (!this.enableTimeValue || !date) return;

    if (this.rangeValue) {
      this.updateTimeInputsRangeMode(date);
    } else {
      this.updateTimeInputsSingleMode(date);
    }
  }

  isValidDatetime(date) {
    const minDate = this.minDateValue ? DateTime.fromISO(this.minDateValue) : null;
    const maxDate = this.maxDateValue ? DateTime.fromISO(this.maxDateValue) : null;
    const now = DateTime.now();

    if (minDate && date < minDate) return false;
    if (maxDate && date > maxDate) return false;
    if (this.futureOnlyValue && date < now) return false;

    return true;
  }

  isValidDate(date) {
    date = date.setZone('Etc/UTC', { keepLocalTime: true }).startOf('day');

    const minDate = this.minDateValue
      ? DateTime.fromISO(this.minDateValue).setZone('Etc/UTC', { keepLocalTime: true }).startOf('day')
      : null;
    const maxDate = this.maxDateValue
      ? DateTime.fromISO(this.maxDateValue).setZone('Etc/UTC', { keepLocalTime: true }).startOf('day')
      : null;
    const now = DateTime.now().setZone('Etc/UTC', { keepLocalTime: true }).startOf('day');

    if (minDate && date < minDate) return false;
    if (maxDate && date > maxDate) return false;
    if (this.futureOnlyValue && date < now) return false;

    return true;
  }

  clear() {
    this.state.dispatch('date/propose', null);
    this.updateDateInput();

    if (!this.floating.opened()) {
      this.fireChange();
    }
  }

  cancel() {
    this.floating.close();

    if (!this.state.lastDispatchedValue) {
      this.state.dispatch('date/propose', null);
    } else {
      const newProposedDate = !this.state.lastDispatchedValue.includes('/')
        ? DateTime.fromISO(this.state.lastDispatchedValue).setZone(this.selectedTimezone, {
            keepLocalTime: true,
          })
        : Interval.fromISO(this.state.lastDispatchedValue).mapEndpoints((date) =>
            date.setZone(this.selectedTimezone, {
              keepLocalTime: true,
            }),
          );

      this.state.dispatch('date/propose', newProposedDate);
    }

    this.updateDateInput();
  }

  confirm() {
    this.floating.close();
    this.updateDateInput();
    this.fireChange();
  }

  confirmOnEnter(event) {
    if (!this.floating.opened()) return;

    event.preventDefault();
    event.stopPropagation();

    this.confirm();
  }

  fireChange() {
    if (
      (!this.state.proposedDate && this.state.lastDispatchedValue === '') ||
      (this.state.proposedDate &&
        ((this.enableTimeValue && this.state.lastDispatchedValue === this.dateTimeISO(this.state.proposedDate)) ||
          (!this.enableTimeValue && this.state.lastDispatchedValue === this.dateISO(this.state.proposedDate))))
    ) {
      return;
    }

    let value;

    if (!this.useSeparateInputsForRangeValue) {
      if (this.rangeValue) {
        value = {
          start: this.resultInputTarget.dataset.enDateStart || '',
          end: this.resultInputTarget.dataset.enDateEnd || '',
        };
      } else {
        value = this.resultInputTarget.dataset.enDate || '';
      }
    } else if (this.rangeValue) {
      value = { start: this.resultInputTarget.start.value, end: this.resultInputTarget.end.value };
    } else {
      value = this.resultInputTarget.value;
    }

    this.originalInputTarget.dispatchEvent(new Event('change', { bubbles: true }));
    this.originalInputTarget.dispatchEvent(new Event('input', { bubbles: true }));
    this.dispatch('change', { detail: { value } });
    this.state.dispatch('dispatched');
  }

  dateISOSingleMode(date) {
    return date.setZone('Etc/UTC', { keepLocalTime: true }).toISODate();
  }

  dateISORangeMode(date) {
    if (DateTime.isDateTime(date)) {
      return this.dateISOSingleMode(date);
    }

    return date.mapEndpoints((d) => d.setZone('Etc/UTC', { keepLocalTime: true })).toISODate();
  }

  dateISO(date) {
    if (this.rangeValue) {
      return this.dateISORangeMode(date);
    }

    return this.dateISOSingleMode(date);
  }

  dateTimeISO(date) {
    return date.toISO({ suppressMilliseconds: true }).replace(/\+00:00$/, 'Z');
  }

  timeTargetByDateISOSingleMode(targets, dateISO) {
    const timeIdObject = this.timeIds.find((it) => it.dateISO === dateISO);

    return targets.find(({ dataset: { timeId } }) => timeId === timeIdObject.timeId);
  }

  timeTargetByDateISORangeMode(targets, dateISO) {
    const timeIdObject = this.timeIds.find((it) => it.dateISO === dateISO);

    return {
      start: targets.find(({ dataset: { timeId } }) => timeId === timeIdObject.start.timeId),
      end: targets.find(({ dataset: { timeId } }) => timeId === timeIdObject.end.timeId),
    };
  }

  timeForSingleDate(date) {
    const dateISO = this.dateISO(date);
    const hourInputTarget = this.timeTargetByDateISOSingleMode(this.hourInputTargets, dateISO);
    const minuteInputTarget = this.timeTargetByDateISOSingleMode(this.minuteInputTargets, dateISO);
    const secondInputTarget = this.timeTargetByDateISOSingleMode(this.secondInputTargets, dateISO);

    return {
      hour: Number(hourInputTarget?.dataset?.in24Hour || 0),
      minute: Number(minuteInputTarget?.value || 0),
      second: Number(secondInputTarget?.value || 0),
    };
  }

  timeForRangeDate(date) {
    const dateISO = this.dateISO(date);
    const hourInputTarget = this.timeTargetByDateISORangeMode(this.hourInputTargets, dateISO);
    const minuteInputTarget = this.timeTargetByDateISORangeMode(this.minuteInputTargets, dateISO);
    const secondInputTarget = this.timeTargetByDateISORangeMode(this.secondInputTargets, dateISO);

    return {
      start: {
        hour: Number(hourInputTarget.start?.dataset?.in24Hour || 0),
        minute: Number(minuteInputTarget.start?.value || 0),
        second: Number(secondInputTarget.start?.value || 0),
      },
      end: {
        hour: Number(hourInputTarget.end?.dataset?.in24Hour || 0),
        minute: Number(minuteInputTarget.end?.value || 0),
        second: Number(secondInputTarget.end?.value || 0),
      },
    };
  }

  timeForDate(date) {
    if (this.rangeValue) {
      return this.timeForRangeDate(date);
    }

    return this.timeForSingleDate(date);
  }

  timeTargetByTimeId(targets, timeId) {
    return targets.find((target) => target.dataset.timeId === timeId);
  }

  timeForTimeId(timeId) {
    const hourInputTarget = this.timeTargetByTimeId(this.hourInputTargets, timeId);
    const minuteInputTarget = this.timeTargetByTimeId(this.minuteInputTargets, timeId);
    const secondInputTarget = this.timeTargetByTimeId(this.secondInputTargets, timeId);

    return {
      hour: Number(hourInputTarget?.dataset?.in24Hour || 0),
      minute: Number(minuteInputTarget?.value || 0),
      second: Number(secondInputTarget?.value || 0),
    };
  }

  formattedHourForDate(date) {
    return date
      .toLocaleString({ hour: 'numeric', ...this.hourFormatSpecificOptions }, { locale: this.locale })
      .split(' ')[0];
  }

  renderYearPicker() {
    let year = this.renderedMonth.plus({ year: this.state.yearRange * 12 }).year - 5;

    for (const yearButtonTarget of this.yearButtonTargets) {
      yearButtonTarget.dataset.year = year;
      yearButtonTarget.textContent = year;

      year++;
    }
  }

  daySpecificClassesSingleMode(dayDate) {
    const result = { day: [], button: [] };

    if (this.state.proposedDate.hasSame(dayDate, 'day')) {
      result.button.push(...this.selectedButtonClasses);
    }

    return result;
  }

  daySpecificClassesRangeMode(dayDate, proposedRange = null) {
    const result = { day: [], button: [] };

    if (DateTime.isDateTime(this.state.proposedDate)) {
      if (proposedRange) {
        if (proposedRange.start.startOf('day') <= dayDate && proposedRange.end.endOf('day') >= dayDate) {
          result.day.push(...this.inRangeClasses);
        }

        if (proposedRange.start.hasSame(dayDate, 'day')) {
          result.day.push(...this.startRangeClasses);
        }

        if (proposedRange.end.hasSame(dayDate, 'day')) {
          result.day.push(...this.endRangeClasses);
        }
      }

      const singleClasses = this.daySpecificClassesSingleMode(dayDate);

      result.day.push(...singleClasses.day);
      result.button.push(...singleClasses.button);

      return result;
    }

    if (
      this.state.proposedDate.start.startOf('day') <= dayDate &&
      this.state.proposedDate.end.endOf('day') >= dayDate
    ) {
      result.day.push(...this.inRangeClasses);
    }

    if (this.state.proposedDate.start.hasSame(dayDate, 'day')) {
      result.day.push(...this.startRangeClasses);
      result.button.push(...this.selectedButtonClasses);
    }

    if (this.state.proposedDate.end.hasSame(dayDate, 'day')) {
      result.day.push(...this.endRangeClasses);
      result.button.push(...this.selectedButtonClasses);
    }

    return result;
  }

  renderCalendar() {
    const today = DateTime.now();
    const startOfRenderedMonth = this.renderedMonth.startOf('month').startOf('day');
    const startOfLastWeekInRenderedMonth = startOfRenderedMonth
      .endOf('month')
      .startOf('week', { useLocaleWeeks: true });
    let dayDate = startOfRenderedMonth.startOf('week', { useLocaleWeeks: true });
    let shouldHideDay = false;
    let proposedRange = null;

    if (this.rangeValue && this.state.hoveredDate && !Interval.isInterval(this.state.proposedDate)) {
      proposedRange = Interval.fromDateTimes(...[this.state.proposedDate, this.state.hoveredDate].sort());
    }

    this.yearTarget.textContent = startOfRenderedMonth.year;
    this.monthTarget.textContent = this.monthButtonTargets[startOfRenderedMonth.month - 1].dataset.monthName;

    for (let i = 0; i < this.dayTargets.length; i++) {
      const dayTarget = this.dayTargets[i];
      const dayButtonTarget = this.dayButtonTargets[i];
      const isInMonth = dayDate.month === startOfRenderedMonth.month;
      const daySpecificClasses = [];
      const dayButtonSpecificClasses = [];

      dayButtonTarget.disabled = !this.isValidDate(dayDate);
      dayButtonTarget.textContent = dayDate.day;
      dayButtonTarget.dataset.date = this.dateTimeISO(dayDate);

      if (i < 7) {
        daySpecificClasses.push('firstRow');
      } else if (startOfLastWeekInRenderedMonth <= dayDate) {
        daySpecificClasses.push('lastRow');
      }

      if (i % 7 === 0) {
        daySpecificClasses.push('firstCol');
      } else if (i % 7 === 6) {
        daySpecificClasses.push('lastCol');
      }

      if (this.weekendWeekdaysIndexes.includes(i % 7)) {
        daySpecificClasses.push('weekend', ...this.weekendDayClasses);
      }

      if (!isInMonth) {
        dayButtonSpecificClasses.push(...this.otherMonthButtonClasses);

        if (dayDate > startOfRenderedMonth && i % 7 === 0) {
          shouldHideDay = true;
        }
      }

      if (dayDate.hasSame(today, 'day')) {
        dayButtonSpecificClasses.push(...this.todayButtonClasses);
      }

      dayTarget.hidden = shouldHideDay;

      if (!this.state.proposedDate) {
        dayTarget.className = resolveTwClasses(this.originalDayClasses, daySpecificClasses);
        dayButtonTarget.className = resolveTwClasses(this.originalDayButtonClasses, dayButtonSpecificClasses);
        dayDate = dayDate.plus({ day: 1 });

        continue;
      }

      let classes;

      if (this.rangeValue) {
        if (proposedRange && this.isValidDate(dayDate)) {
          classes = this.daySpecificClassesRangeMode(dayDate, proposedRange);
        } else {
          classes = this.daySpecificClassesRangeMode(dayDate);
        }
      } else {
        classes = this.daySpecificClassesSingleMode(dayDate);
      }

      daySpecificClasses.push(...classes.day);
      dayButtonSpecificClasses.push(...classes.button);

      dayTarget.className = resolveTwClasses(this.originalDayClasses, daySpecificClasses);
      dayButtonTarget.className = resolveTwClasses(this.originalDayButtonClasses, dayButtonSpecificClasses);
      dayDate = dayDate.plus({ day: 1 });
    }
  }

  stateReducer(store, action, payload) {
    switch (action) {
      case 'date/propose': {
        store.proposedDate = payload;
        store.hoveredDate = null;
        this.clearButtonTarget.hidden = !this.clearableValue || !store.proposedDate;

        break;
      }
      case 'date/hover':
        store.hoveredDate = payload;
        break;
      case 'month/next':
        store.month++;
        this.monthValue = store.month;
        break;
      case 'month/previous':
        store.month--;
        this.monthValue = store.month;
        break;
      case 'month/current':
        store.month = 0;
        this.monthValue = store.month;
        break;
      case 'month/set':
        store.month = payload;
        this.monthValue = store.month;
        break;
      case 'year-range/next':
        store.yearRange++;
        break;
      case 'year-range/previous':
        store.yearRange--;
        break;
      case 'year-range/current':
        store.yearRange = 0;
        break;
      case 'dispatched':
        if (!store.proposedDate) {
          store.lastDispatchedValue = '';
        } else if (this.enableTimeValue) {
          store.lastDispatchedValue = this.dateTimeISO(store.proposedDate);
        } else {
          store.lastDispatchedValue = this.dateISO(store.proposedDate);
        }

        break;
      default:
    }
  }

  get renderedMonth() {
    let date = DateTime.now();

    if (this.minDateValue) {
      const minDate = DateTime.fromISO(this.minDateValue);

      if (minDate > date) {
        date = minDate;
      }
    }

    if (this.maxDateValue) {
      const maxDate = DateTime.fromISO(this.maxDateValue);

      if (maxDate < date) {
        date = maxDate;
      }
    }

    date = date.setZone(this.selectedTimezone, {
      keepLocalTime: true,
    });

    return date.plus({ month: this.state.month });
  }

  get selectedTimezone() {
    if (this.enableTimeValue) {
      if (this.disableTimezonesValue) {
        return this.altTimezoneValue || 'local';
      }

      return this.timezoneSelectTarget.value;
    }

    return 'Etc/UTC';
  }

  get selectedTimezoneForChronoJs() {
    return DateTime.now().setZone(this.selectedTimezone).toFormat('ZZZZ');
  }

  get format() {
    if (this.altFormatValue) {
      return this.altFormatValue;
    }

    const options = {};

    if (this.shouldFormatWithTimezone) {
      options.timeZoneName = 'short';
    }

    if (this.enableTimeValue) {
      if (this.enableSecondsValue) {
        return { ...options, ...DateTime.DATETIME_MED_WITH_SECONDS, ...this.hourFormatSpecificOptions };
      }

      return { ...options, ...DateTime.DATETIME_MED, ...this.hourFormatSpecificOptions };
    }

    return { ...options, ...DateTime.DATE_MED, ...this.hourFormatSpecificOptions };
  }

  get hourFormatSpecificOptions() {
    const options = {};

    if (this.is24HourFormat) {
      options.hourCycle = 'h23';
    }

    return options;
  }

  get shouldFormatWithTimezoneSingleMode() {
    const local = DateTime.now();

    return local.zoneName !== this.state.proposedDate.zoneName;
  }

  get shouldFormatWithTimezoneRangeMode() {
    if (DateTime.isDateTime(this.state.proposedDate)) {
      return this.shouldFormatWithTimezoneSingleMode;
    }

    const local = DateTime.now();
    const rendered = this.state.proposedDate.start;

    return local.zoneName !== rendered.zoneName;
  }

  get shouldFormatWithTimezone() {
    if (this.disableTimezonesValue) return false;
    if (!this.enableTimeValue) return false;
    if (!this.state.proposedDate) return true;

    if (this.rangeValue) {
      return this.shouldFormatWithTimezoneRangeMode;
    }

    return this.shouldFormatWithTimezoneSingleMode;
  }

  get firstDayOfWeekIndex() {
    return Info.getStartOfWeek() - 1;
  }

  get weekendWeekdaysIndexes() {
    return Info.getWeekendWeekdays().map((day) => (day - 1 - this.firstDayOfWeekIndex + 7) % 7);
  }

  get locale() {
    return gon.locale || 'en';
  }
}
