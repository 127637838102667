import { addListener, createElementFromHTML } from '@slideslive/fuse-kit/utils';

export default class TableTooltipComponent {
  constructor(config, attrs) {
    this.data = { config, attrs };
    this.elements = {
      dom: this.renderDom(),
      addRowBeforeButton: null,
      addRowAfterButton: null,
      addColBeforeButton: null,
      addColAfterButton: null,
      deleteSelectedCellsButton: null,
      setAlignLeftButton: null,
      setAlignCenterButton: null,
      setAlignRightButton: null,
    };
    this.callbacks = {};

    this.renderActions();
  }

  renderDom() {
    return createElementFromHTML('<div class="tw-flex tw-gap-1"></div>');
  }

  renderActions() {
    this.elements = {
      ...this.elements,
      addRowBeforeButton: null,
      addRowAfterButton: null,
      addColBeforeButton: null,
      addColAfterButton: null,
      deleteSelectedCellsButton: null,
      setAlignLeftButton: null,
      setAlignCenterButton: null,
      setAlignRightButton: null,
    };

    this.domElement.innerHTML = '';

    for (let i = 0; i < this.actionsConfig.length; i++) {
      const action = this.actionsConfig[i];
      const { key, shouldRender, actionName = null, attrs = [] } = action;

      if (!shouldRender) continue;

      const button = createElementFromHTML(this.config[`${key}Html`]);

      this.elements[`${key}Button`] = button;
      addListener(button, 'click', () => this.runCallback(actionName || key, ...attrs));

      this.domElement.insertAdjacentElement('beforeend', button);
    }
  }

  runCallback(name, ...attrs) {
    for (const callback of this.callbacks[name] || []) {
      callback(...attrs);
    }
  }

  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  remove() {
    this.domElement.remove();
  }

  get actionsConfig() {
    const { isWholeTable, isHeading, isRow, isCol, isAny } = this.attrs;

    return [
      {
        key: 'addRowBefore',
        shouldRender: !isWholeTable && !isHeading && isRow,
      },
      {
        key: 'addColBefore',
        shouldRender: !isWholeTable && isCol,
      },
      {
        key: 'deleteSelectedCells',
        shouldRender: isWholeTable || (!isHeading && isAny),
      },
      {
        key: 'addRowAfter',
        shouldRender: !isWholeTable && isRow,
      },
      {
        key: 'addColAfter',
        shouldRender: !isWholeTable && isCol,
      },
      {
        key: 'setAlignLeft',
        shouldRender: !isWholeTable && isCol,
        actionName: 'setAlign',
        attrs: ['left'],
      },
      {
        key: 'setAlignCenter',
        shouldRender: !isWholeTable && isCol,
        actionName: 'setAlign',
        attrs: ['center'],
      },
      {
        key: 'setAlignRight',
        shouldRender: !isWholeTable && isCol,
        actionName: 'setAlign',
        attrs: ['right'],
      },
    ];
  }

  set attrs(value) {
    const rerenderActions = JSON.stringify(this.attrs) !== JSON.stringify(value);

    this.data.attrs = value;

    if (rerenderActions) {
      this.renderActions();
    }
  }

  get attrs() {
    return this.data.attrs;
  }

  set config(value) {
    const rerenderActions = JSON.stringify(this.config) !== JSON.stringify(value);

    this.data.config = value;

    if (rerenderActions) {
      this.renderActions();
    }
  }

  get config() {
    return this.data.config;
  }

  get domElement() {
    return this.elements.dom;
  }

  get addRowBeforeButtonElement() {
    return this.elements.addRowBeforeButton;
  }

  get addRowAfterButtonElement() {
    return this.elements.addRowAfterButton;
  }

  get addColBeforeButtonElement() {
    return this.elements.addColBeforeButton;
  }

  get addColAfterButtonElement() {
    return this.elements.addColAfterButton;
  }

  get deleteSelectedCellsButtonElement() {
    return this.elements.deleteSelectedCellsButton;
  }

  get setAlignLeftButtonElement() {
    return this.elements.setAlignLeftButton;
  }

  get setAlignCenterButtonElement() {
    return this.elements.setAlignCenterButton;
  }

  get setAlignRightButtonElement() {
    return this.elements.setAlignRightButton;
  }
}
