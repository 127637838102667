import { defer } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    defer(() => {
      this.dispatchOnDocument('typeChanged', { detail: { type: this.type }, prefix: 'search' });
    });
  }

  get type() {
    return this.element.dataset.type;
  }
}
