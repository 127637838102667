import { editorViewCtx } from '@milkdown/core';
import { linkSchema } from '@milkdown/preset-commonmark';
import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import { TextSelection } from 'prosemirror-state';

import { TooltipProvider } from '../../tooltip_plugin';
import { linkTooltipConfigCtx, linkTooltipMarkCtx, linkTooltipModeCtx } from '../config';
import { findCurrentLinkMarkAndPosition } from '../utils';
import LinkEditComponent from './component';

export default class LinkEditTooltip {
  constructor(ctx, view) {
    this.ctx = ctx;
    this.view = view;

    this.content = new LinkEditComponent(this.ctx.get(linkTooltipConfigCtx.key));
    this.provider = new TooltipProvider({
      content: this.content.domElement,
      debounce: 0,
      shouldShow: () => false,
      tooltipOptions: {
        disableAnimation: true,
        invisibleInsteadOfHide: true,
      },
    });

    this.provider.update(view);

    this.content.on('confirm', this.confirmEdit.bind(this));
    this.content.on('cancel', this.reset.bind(this));

    this.provider.on('hide', () => {
      if (!view.hasFocus()) {
        ctx.get(editorViewCtx).dom.focus();
      }
    });
  }

  reset() {
    this.provider.hide();

    this.ctx.update(linkTooltipMarkCtx.key, () => ({ mark: null, from: -1, to: -1 }));
    this.ctx.update(linkTooltipModeCtx.key, () => ({ mode: 'preview' }));
  }

  confirmEdit(href) {
    const { mark, from, to } = this.ctx.get(linkTooltipMarkCtx.key);
    if (mark && mark.attrs.href === href) {
      this.reset();
      return;
    }

    const tr = this.view.state.tr;

    if (mark) {
      tr.removeMark(from, to, mark);
    }

    tr.addMark(from, to, linkSchema.type(this.ctx).create({ href }));
    this.view.dispatch(tr);

    this.reset();
  }

  enterEditMode() {
    const { mark, from, to } = this.ctx.get(linkTooltipMarkCtx.key);
    const config = this.ctx.get(linkTooltipConfigCtx.key);

    this.content.config = config;
    this.content.href = mark?.attrs?.href ?? '';

    this.ctx.update(linkTooltipModeCtx.key, () => ({ mode: 'edit' }));

    this.view.dispatch(this.view.state.tr.setSelection(TextSelection.create(this.view.state.doc, from, to)));

    this.provider.updateTooltipVirtualElementCustomPosition(this.view, from, to);
    this.provider.show();

    requestAnimationFrame(() => {
      this.content.inputElement?.focus();
    });
  }

  update(view, prevState) {
    if (prevState && prevState.selection.eq(view.state.selection)) return;

    const { mode } = this.ctx.get(linkTooltipModeCtx.key);

    if (mode !== 'edit') return;

    const { mark: activeMark, from: activeFrom, to: activeTo } = this.ctx.get(linkTooltipMarkCtx.key);
    const { mark, from, to } = findCurrentLinkMarkAndPosition(this.ctx, view);

    if (activeMark && activeMark === mark) return;
    if (!activeMark && activeFrom === from && activeTo === to) return;

    this.reset();
  }

  destroy() {
    this.provider.destroy();
    this.content.remove();
  }

  addLink(from, to) {
    this.ctx.update(linkTooltipMarkCtx.key, () => ({ mark: null, from, to }));
    this.enterEditMode();
  }

  editLink(mark, from, to) {
    if (isVariableDefinedNotNull(mark) && isVariableDefinedNotNull(from) && isVariableDefinedNotNull(to)) {
      this.ctx.update(linkTooltipMarkCtx.key, () => ({ mark, from, to }));
    }

    this.enterEditMode();
  }

  removeLink(from, to) {
    if (!isVariableDefinedNotNull(from) || !isVariableDefinedNotNull(to)) {
      const { from: removeFrom, to: removeTo } = this.ctx.get(linkTooltipMarkCtx.key);

      from = removeFrom;
      to = removeTo;
    }

    const tr = this.view.state.tr;

    tr.removeMark(from, to, linkSchema.type(this.ctx));
    this.view.dispatch(tr);

    this.reset();
  }
}
