import { debounce } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = ['stickyRow'];

  static classes = ['sticky'];

  initialize() {
    this.debouncedToggleStickyIfScrollable = debounce(this.toggleStickyIfScrollable.bind(this), 250);
  }

  connect() {
    this.scrollableParent = null;
    this.scrollableParentOverflowClasses = '';

    this.updateScrollableParent();
    this.toggleStickyIfScrollable();
  }

  updateScrollableParent() {
    this.scrollableParent = this.getScrollableParent();

    if (!this.scrollableParent) {
      this.scrollableParentOverflowClasses = '';

      return;
    }

    this.scrollableParentOverflowClasses = [].slice
      .call(this.scrollableParent.classList)
      .filter((c) => c.match(/(-overflow-([xy]-)?|^overflow-([xy]-)?)(auto|scroll|overlay|hidden)$/));
  }

  toggleStickyIfScrollable() {
    if (!this.scrollableParent) return;

    this.scrollableParent.classList.add(...this.scrollableParentOverflowClasses);

    const hasScrollableContent = this.scrollableParent.scrollWidth > this.scrollableParent.clientWidth;

    if (!this.hasStickyRowTarget) return;

    if (hasScrollableContent) {
      this.stickyRowTarget.classList.remove(...this.stickyClasses);
    } else {
      this.scrollableParent.classList.remove(...this.scrollableParentOverflowClasses);
      this.stickyRowTarget.classList.add(...this.stickyClasses);
    }
  }

  scheduleToggleStickyIfScrollable() {
    this.debouncedToggleStickyIfScrollable();
  }

  getScrollableParent() {
    let parent = this.element.parentElement;

    while (parent) {
      const style = window.getComputedStyle(parent);
      const isScrollable = /(auto|scroll|overlay|hidden)/.test(style.overflow + style.overflowX + style.overflowY);

      if (isScrollable) {
        return parent;
      }

      parent = parent.parentElement;
    }

    return null;
  }
}
