import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get values() {
    return {
      ...super.values,
      showUrl: {
        type: String,
        default: undefined,
      },
      editUrl: {
        type: String,
        default: undefined,
      },
    };
  }

  #checkTimeout = null;
  #abortRequest = null;

  connect() {
    this.#check();
  }

  disconnect() {
    if (this.#abortRequest) this.#abortRequest();

    clearTimeout(this.#checkTimeout);
    this.#checkTimeout = null;
  }

  #scheduleCheck() {
    if (this.#checkTimeout) clearTimeout(this.#checkTimeout);

    this.#checkTimeout = setTimeout(this.#check.bind(this), 1000);
  }

  #check() {
    if (!this.showUrlValue) return;

    const { request, abort } = this.fetchTurboStream(this.showUrlValue);
    this.#abortRequest = abort;

    request.then(() => this.#scheduleCheck()).catch(() => this.#scheduleCheck());
  }
}
