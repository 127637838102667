import { Turbo } from '@hotwired/turbo-rails';
import { checkDelayedResult, remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  initialize() {
    this.delayedResultCheck = null;
  }

  connect() {
    this.delayedResultCheck = checkDelayedResult(
      this.delayedResultId,
      (result) => {
        this.delayedResultCheck = null;

        if (result.turbo_stream) {
          Turbo.renderStreamMessage(result.turbo_stream);
        }

        this.dispatch('done', {
          target: window,
          detail: { delayedResultId: this.delayedResultId, success: result.success },
        });

        remove(this.element);
      },
      this.checkInterval,
      (errors) => {
        console.warn(this.identifier, this.delayedResultId, errors);

        this.delayedResultCheck = null;
        remove(this.element);
      },
    );
  }

  disconnect() {
    if (this.delayedResultCheck) {
      this.delayedResultCheck.abort();
      this.delayedResultCheck = null;
    }
  }

  get delayedResultId() {
    return this.element.dataset.delayedResultId;
  }

  get checkInterval() {
    return parseInt(this.element.dataset.delayedResultCheckInterval, 10) || 2000;
  }
}
