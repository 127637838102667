import I18n from '@/fuse/javascript/i18n';
import { isArray, isVariableDefinedNotNull, toCamelCase, toUnderscore } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

const STATES = ['success', 'warning', 'error'];
const VALIDITY_KEYS = [
  'badInput',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
  'typeMismatch',
  'valueMissing',
];

export default class extends ApplicationController {
  static targets = ['label', 'body', 'input'];

  static values = {
    initialState: {
      type: String,
      default: 'default',
    },
    initialValidationMessage: {
      type: String,
      default: '',
    },
    constraints: {
      type: Array,
      default: null,
    },
    submitConstraints: {
      type: Array,
      default: null,
    },
    disableHtml5Validation: {
      type: Boolean,
      default: false,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
    validateOnChange: {
      type: Boolean,
      default: true,
    },
  };

  static classes = ['validityMessage'];

  initialize() {
    this.isValid = true;
    this.alreadyHadError = false;
    this.submitError = false;
    this.validityMessageTarget = null;
    this.formNode = null;

    if (this.hasInputTarget) {
      this.inputTarget.stimulusController = this;
    }
  }

  connect() {
    if (!this.hasInputTarget) {
      this.initState();

      return;
    }

    this.setupFormConnection();

    this.initState();
  }

  disconnect() {
    if (!this.hasInputTarget) return;

    this.handleFormDisconnection();
  }

  setupFormConnection() {
    this.formNode = this.element.closest('form');

    if (this.formNode) {
      this.dispatch('added', {
        target: this.formNode,
        detail: {
          stimulusController: this,
          name: this.inputTarget.name,
        },
      });
    }
  }

  initState() {
    if (!STATES.includes(this.initialStateValue)) return;

    this.setValidity(this.initialStateValue, this.initialValidationMessageValue);
  }

  handleFormDisconnection() {
    if (!this.formNode) return;

    this.dispatch('removed', {
      target: this.formNode,
      detail: { name: this.inputTarget.name },
    });

    this.formNode = null;
  }

  handleBlur() {
    if (this.validateOnBlurValue && !this.submitError) {
      this.triggerValidate();
    }
  }

  handleInput() {
    if (this.validateOnChangeValue) {
      this.triggerValidate();
    }
  }

  handleChange() {
    if (this.validateOnChangeValue) {
      this.triggerValidate();
    }
  }

  resetValidity() {
    this.setValidity('reset');
    this.isValid = true;
    this.alreadyHadError = false;
    this.submitError = false;
  }

  triggerValidate() {
    if (!this.isValid || this.alreadyHadError) {
      this.validate();
    }
  }

  setInvalid(message) {
    this.alreadyHadError = true;
    this.setValidity('error', message || this.inputTarget.validationMessage);
  }

  setValidity(type, message) {
    if (type === 'reset') {
      this.setValidity(this.initialStateValue, this.initialValidationMessageValue);

      return;
    }

    this.updateValidityClasses(type);
    this.updateValidityMessage(message);
  }

  updateValidityClasses(type) {
    STATES.forEach((state) => {
      this.element.classList.toggle(state, state === type);
    });
  }

  updateValidityMessage(message) {
    if (!message) {
      if (this.validityMessageTarget) {
        this.validityMessageTarget.remove();
        this.validityMessageTarget.innerHTML = '';
      }

      return;
    }

    if (!this.validityMessageTarget) {
      this.validityMessageTarget = document.createElement('p');
      this.validityMessageTarget.classList.add(...this.validityMessageClasses);
    }

    this.validityMessageTarget.innerHTML = message;

    if (this.bodyTarget.nextElementSibling !== this.validityMessageTarget) {
      this.bodyTarget.insertAdjacentElement('afterend', this.validityMessageTarget);
    }
  }

  validate(isSubmit = false) {
    if (this.shouldTrimValue(isSubmit)) {
      this.inputTarget.value = this.inputTarget.value.trim();
    }

    const valid = this.performValidation(isSubmit);
    this.updateValidityState(valid);

    return valid;
  }

  shouldTrimValue(isSubmit) {
    return (
      isSubmit &&
      !this.disableHtml5ValidationValue &&
      this.inputTarget.tagName.toLowerCase() !== 'select' &&
      typeof this.inputTarget.value === 'string'
    );
  }

  performValidation(isSubmit) {
    let valid = this.checkHtml5Validity();

    if (valid) {
      valid = this.checkCustomValidity();
    }

    if (valid && isSubmit) {
      valid = this.checkCustomValidity(this.submitConstraintsValue);
      this.submitError = !valid;
    }

    if (valid) {
      this.setValidity(null, null);
    }

    return valid;
  }

  updateValidityState(valid) {
    if (this.isValid !== valid) {
      this.isValid = valid;

      this.dispatch('validityChanged', {
        detail: {
          name: this.inputTarget.name,
          isValid: valid,
        },
      });
    }
  }

  checkHtml5Validity() {
    if (this.disableHtml5ValidationValue || this.inputTarget.checkValidity()) return true;

    for (const validityKey of VALIDITY_KEYS) {
      if (!this.inputTarget.validity[validityKey]) continue;

      const errorMessage = this.getHtml5ErrorMessage(validityKey);
      this.setInvalid(errorMessage);

      return false;
    }

    return true;
  }

  getHtml5ErrorMessage(validityKey) {
    const snakeCaseValidityKey = toUnderscore(validityKey);
    const type = this.inputTarget.type;

    switch (validityKey) {
      case 'badInput':
        return type === 'number'
          ? I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.${type}`)
          : I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.default`);
      case 'patternMismatch': {
        let message = I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.default`);
        if (this.element.placeholder) {
          message += ` ${I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.example`, {
            example: this.inputTarget.placeholder,
          })}`;
        }
        return message;
      }
      case 'typeMismatch':
        return I18n.t(`fuse.form.errors.${snakeCaseValidityKey}.${type}`);
      case 'valueMissing':
        return I18n.t(`fuse.form.errors.${snakeCaseValidityKey}`);
      default:
        return this.getAttributeBasedErrorMessage(validityKey, snakeCaseValidityKey);
    }
  }

  getAttributeBasedErrorMessage(validityKey, snakeCaseValidityKey) {
    const attributeMap = {
      rangeOverflow: 'max',
      rangeUnderflow: 'min',
      tooLong: 'maxlength',
      tooShort: 'minlength',
      stepMismatch: 'step',
    };

    const attribute = attributeMap[validityKey];
    const value = this.inputTarget.getAttribute(attribute) || (validityKey === 'stepMismatch' ? 1 : null);

    return I18n.t(`fuse.form.errors.${snakeCaseValidityKey}`, { value });
  }

  checkCustomValidity(constraints = this.constraintsValue) {
    if (!constraints) return true;

    const constraintArray = isArray(constraints) ? constraints : [constraints];

    return constraintArray.every((constraint) => this.validateConstraint(constraint));
  }

  validateConstraint(constraint) {
    const { pattern = null, message = null, target = null, value = null } = constraint;
    const key = constraint.key ? toCamelCase(constraint.key) : null;
    const errorMatches = constraint.errorMatches || constraint.error_matches || false;

    const result = this.evaluateConstraint(key, pattern, target, value);

    if ((errorMatches && result) || (!errorMatches && !result)) {
      this.handleConstraintViolation(key, message);

      return false;
    }

    return true;
  }

  evaluateConstraint(key, pattern, target, value) {
    if (pattern) {
      return new RegExp(pattern, '').test(this.inputTarget.value);
    }

    if (key === 'equals') {
      return this.inputTarget.value === document.querySelector(target)?.value;
    }

    if (key === 'requiredIfTargetEqualsValue') {
      return document.querySelector(target)?.value !== value || this.inputTarget.value !== '';
    }

    if (key === 'requiredIfFirstChild') {
      const container = this.inputTarget.closest(target);
      const children = Array.from(container.parentElement.children).filter((child) => child.matches(target));
      const myIndex = children.indexOf(container);

      return myIndex !== 0 || !!this.inputTarget.value?.trim();
    }

    if (isVariableDefinedNotNull(this.inputTarget.dataset[key])) {
      return this.inputTarget.dataset[key] === 'true';
    }

    return true;
  }

  handleConstraintViolation(key, message) {
    let errorMessage = message || '';

    if (!errorMessage && key) {
      const snakeCaseKey = toUnderscore(key);

      errorMessage = I18n.t(`fuse.form.errors.${snakeCaseKey}`);

      if (/^\[missing ".+" translation]$/.test(errorMessage)) {
        errorMessage = '';
      }
    }

    this.setInvalid(errorMessage);
  }

  get labelText() {
    if (this.hasLabelTarget) return this.labelTarget.textContent.trim();

    if (this.hasInputTarget) {
      const ariaLabel = this.inputTarget.getAttribute('aria-label')?.trim();

      if (ariaLabel) return ariaLabel;
    }

    return null;
  }
}
