import {
  defer,
  releaseFocus,
  removeBodyOverflowHidden,
  setBodyOverflowHidden,
  trapFocus,
} from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['content', 'typeInput', 'query', 'queryInput', 'result', 'spinnerTemplate'];
  }

  initialize() {
    this.active = false;
    this.lastSearchedQuery = '';
  }

  connect() {
    if (this.isTurboPreview) {
      return;
    }

    this.initializeQueryFromWindowUrl();

    if (this.hasResultTarget) {
      defer(() => (this.resultController.disabled = this.isSearchPage));
    }
  }

  disconnect() {
    if (this.isTurboPreview) {
      return;
    }

    this.close();
    this.reset();
  }

  initializeQueryFromWindowUrl() {
    if (!this.isSearchPage) {
      return;
    }

    const url = new URL(window.location);
    const query = url.searchParams?.get('query')?.trim();
    if (query) {
      this.query = query;
    }

    const type = url.searchParams?.get('type')?.trim();
    if (type) {
      this.searchType = type;
    }
  }

  closeOnEscape(event) {
    if (!this.active) {
      return;
    }

    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();

      this.close();
    }
  }

  updateSearchDetails({ detail: { type } }) {
    this.searchType = type;

    this.updateWindowUrl();
  }

  search() {
    this.lastSearchedQuery = this.query;
    this.dispatchOnDocument('loadContent', { prefix: 'search', detail: { query: this.query, type: this.searchType } });
  }

  searchFromEvent(event) {
    if (event.key === 'Enter') return;

    const newQuery = event.target.value.trim();

    if (this.query !== newQuery) return;

    this.query = newQuery;

    if (this.lastSearchedQuery === this.query) return;

    this.updateWindowUrl();

    if (newQuery.length === 1) return;

    this.search();
  }

  closeOnSearchPage(event) {
    if (!this.isSearchPage) {
      return;
    }

    if (event.relatedTarget && this.contentTarget.contains(event.relatedTarget)) {
      return;
    }

    this.close();
  }

  open() {
    if (this.active) {
      return;
    }

    this.active = true;

    this.contentTarget.classList.add(...this.activeClasses);
    this.element.classList.add(...this.frameActiveClasses);

    if (!this.isSearchPage) {
      setBodyOverflowHidden();
      trapFocus(this.contentTarget, { omitPreviousActive: true });
    } else {
      this.queryInputTarget.focus();
    }
  }

  close() {
    if (!this.active) {
      return;
    }

    this.active = false;

    releaseFocus(this.contentTarget);
    removeBodyOverflowHidden();

    this.contentTarget.classList.remove(...this.activeClasses);
    this.element.classList.remove(...this.frameActiveClasses, 'with-results');

    if (document.activeElement === this.queryInputTarget) {
      this.queryInputTarget.blur();
    }

    this.findControllerOnElement(this.queryTarget)?.resetValidity?.();
  }

  reset() {
    this.query = '';
    this.searchType = '';

    this.resultController.reset();
  }

  updateWindowUrl() {
    if (!this.isSearchPage) {
      return;
    }

    const params = { query: this.query, type: this.searchType };

    const state = window.history.state ? { ...window.history.state } : {};
    const url = new URL(window.location);

    for (const key of Object.keys(params)) {
      url.searchParams.set(key, params[key]);
    }

    window.history.replaceState(state, '', url);
  }

  get resultController() {
    return this.findControllerOnElement(this.resultTarget);
  }

  get isSearchPage() {
    return window.location.pathname === '/search';
  }

  get frameActiveClasses() {
    const classes = ['active'];

    if (!this.isSearchPage) {
      classes.push('with-results');
    }

    return classes;
  }

  get activeClasses() {
    return ['fuse-bg-gradient'];
  }

  get query() {
    return this.queryInputTarget.value;
  }

  set query(value) {
    this.queryInputTarget.value = value;

    if (value) {
      this.queryInputTarget.setAttribute('value', value);
    } else {
      this.queryInputTarget.removeAttribute('value');
    }
  }

  get searchType() {
    return this.typeInputTarget.value;
  }

  set searchType(value) {
    this.typeInputTarget.value = value;

    if (value) {
      this.typeInputTarget.setAttribute('value', value);
    } else {
      this.typeInputTarget.removeAttribute('value');
    }
  }
}
