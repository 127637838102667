import {
  autoUpdate,
  computePosition,
  flip as flipMiddleware,
  hide as hideMiddleware,
  offset as offsetMiddleware,
  shift as shiftMiddleware,
  size as sizeMiddleware,
} from '@floating-ui/dom';
import {
  addListener,
  animationsComplete,
  defer,
  isInsideDialog,
  removeListener,
  runCallbacks,
  splashInAnimationClasses,
} from '@slideslive/fuse-kit/utils';

function useFloating(
  context,
  trigger,
  content = null,
  {
    offset = 0,
    placement = 'bottom-start',
    useHide = true,
    useShift = true,
    skipHeaderPadding = false,
    animationClasses = splashInAnimationClasses,
    flipOptions = {},
    shiftOptions = {},
    detectOverflowOptions = {},
  } = {},
) {
  const callbacks = {};
  const listeners = [];
  let opened = false;
  let cleanupFn = null;
  let resolvedPlacement = null;
  let headerHeight = 0;

  // To prevent hiding on scroll triggered by showing virtual keyboard
  let focusinTimeout = null;
  let justFocusedIn = false;

  const updateHeaderHeight = () => {
    if (!skipHeaderPadding && !trigger.closest('header') && !isInsideDialog(trigger)) {
      headerHeight = Number(
        window
          .getComputedStyle(document.querySelector('html'))
          .getPropertyValue('--header-height')
          ?.replace(/\D/g, '') || 0,
      );
    } else {
      headerHeight = 0;
    }
  };

  const doRunCallbacks = (event, ...attrs) => {
    runCallbacks(callbacks[event], ...attrs);
  };

  const computeDetectOverflowOptions = () => {
    const padding = {
      top: headerHeight + 4,
      bottom: 4,
      left: 4,
      right: 4,
    };

    return {
      padding,
      ...detectOverflowOptions,
    };
  };

  const middleware = () => {
    const middlewares = [
      offsetMiddleware(offset),
      flipMiddleware({ ...computeDetectOverflowOptions(), ...flipOptions }),
    ];

    if (useShift) {
      middlewares.push(shiftMiddleware({ ...computeDetectOverflowOptions(), ...shiftOptions }));
    }

    middlewares.push(
      sizeMiddleware({
        ...computeDetectOverflowOptions(),
        apply: ({ availableWidth, availableHeight }) => {
          Object.assign(content.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    );

    if (useHide) {
      middlewares.push(hideMiddleware(computeDetectOverflowOptions()));
    }

    return middlewares;
  };

  const close = async ({ disableAnimation = false } = {}) => {
    if (!opened) return;

    if (!disableAnimation) {
      content.firstElementChild.classList.add('hiding');
    }

    await animationsComplete(content.firstElementChild);

    opened = false;

    doRunCallbacks('close');

    if (!disableAnimation) {
      content.firstElementChild.classList.remove('hiding');
    }

    content.firstElementChild.classList.remove(...animationClasses(resolvedPlacement));

    resolvedPlacement = null;
    cleanupFn?.();
    cleanupFn = null;
  };

  const updatePosition = async () => {
    const {
      x,
      y,
      placement: computedPlacement,
      middlewareData,
    } = await computePosition(trigger, content, { placement, middleware: middleware() });

    if (middlewareData.hide?.referenceHidden && !justFocusedIn) {
      close();

      return;
    }

    const newStyle = {
      transform: `translate3d(${x}px, ${y}px, 0)`,
    };

    if (resolvedPlacement !== computedPlacement) {
      content.firstElementChild.classList.remove(...animationClasses(resolvedPlacement));
      content.firstElementChild.classList.add(...animationClasses(computedPlacement));

      resolvedPlacement = computedPlacement;
    }

    Object.assign(content.style, newStyle);
  };

  const open = () => {
    if (opened) return;
    if (!content) return;

    opened = true;

    doRunCallbacks('open');

    cleanupFn = autoUpdate(trigger, content, updatePosition);
  };

  const on = (event, callback) => {
    if (!callbacks[event]) {
      callbacks[event] = [];
    }

    callbacks[event].push(callback);
  };

  const addListeners = () => {
    if (!content) return;

    if (useHide) {
      listeners.push({
        target: content,
        id: addListener(content, 'focusin', () => {
          if (focusinTimeout) {
            clearTimeout(focusinTimeout);
          }

          justFocusedIn = true;

          focusinTimeout = setTimeout(() => {
            justFocusedIn = false;
          }, 100);
        }),
      });
    }
  };

  const removeListeners = () => {
    for (const { target, id } of listeners) {
      removeListener(target, { id });
    }
  };

  const destroy = () => {
    removeListeners();
    close({ disableAnimation: true });
    headerHeight = 0;
  };

  const set = (values = {}) => {
    if (values.content) {
      removeListeners();

      content = values.content;

      addListeners();
    }

    if (values.offset) {
      offset = values.offset;
    }

    if (values.placement) {
      placement = values.placement;
    }

    if (values.useHide) {
      removeListeners();

      useHide = values.useHide;

      addListeners();
    }

    if (values.useShift) {
      useShift = values.useShift;
    }

    if (values.skipHeaderPadding) {
      skipHeaderPadding = values.skipHeaderPadding;
      updateHeaderHeight();
    }

    if (values.animationClasses) {
      animationClasses = values.animationClasses;
    }

    if (values.flipOptions) {
      flipOptions = values.flipOptions;
    }

    if (values.shiftOptions) {
      shiftOptions = values.shiftOptions;
    }

    if (values.detectOverflowOptions) {
      detectOverflowOptions = values.detectOverflowOptions;
    }
  };

  addListeners();

  defer(() => {
    updateHeaderHeight();
  });

  return {
    open,
    close,
    destroy,
    on,
    set,
    updateHeaderHeight,
    content: () => content,
    opened: () => opened,
  };
}

export default useFloating;
