import { remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get values() {
    return {
      url: {
        type: String,
        value: undefined,
      },
    };
  }

  connect() {
    console.log(this.urlValue);
    if (!this.urlValue) {
      return;
    }

    window.history.replaceState(window.history.state, undefined, this.urlValue);
    remove(this.element);
  }
}
