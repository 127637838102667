import { commandsCtx, editorViewCtx } from '@milkdown/core';
import {
  addColAfterCommand,
  addColBeforeCommand,
  addRowAfterCommand,
  addRowBeforeCommand,
  deleteSelectedCellsCommand,
  setAlignCommand,
} from '@milkdown/preset-gfm';
import { CellSelection } from 'prosemirror-tables';

import { TooltipProvider } from '../../tooltip_plugin';
import { tableTooltipConfigCtx } from '../config';
import tableTooltip from '../tooltip';
import TableTooltipComponent from './component';

export default class TableTooltip {
  constructor(ctx, view) {
    this.ctx = ctx;
    this.content = new TableTooltipComponent(this.ctx.get(tableTooltipConfigCtx.key), this.getAttrsFromView(view));
    this.provider = new TooltipProvider({
      content: this.content.domElement,
      debounce: 0,
      shouldShow: () => false,
    });

    this.provider.update(view);
    this.ctx.update(tableTooltip.key, (prev) => ({
      ...prev,
      provider: this.provider,
    }));

    const callCommand = (command, hide, ...attrs) => {
      this.ctx.get(commandsCtx).call(command.key, ...attrs);

      if (hide) {
        this.provider?.hide();
      } else if (!view.hasFocus()) {
        this.ctx.get(editorViewCtx).dom.focus();
      }
    };

    this.content.on('addRowBefore', callCommand.bind(this, addRowBeforeCommand, true));
    this.content.on('addRowAfter', callCommand.bind(this, addRowAfterCommand, true));
    this.content.on('addColBefore', callCommand.bind(this, addColBeforeCommand, true));
    this.content.on('addColAfter', callCommand.bind(this, addColAfterCommand, true));
    this.content.on('deleteSelectedCells', callCommand.bind(this, deleteSelectedCellsCommand, true));
    this.content.on('setAlign', callCommand.bind(this, setAlignCommand, false));

    this.provider.on('hide', () => {
      if (!view.hasFocus()) {
        ctx.get(editorViewCtx).dom.focus();
      }
    });
  }

  update(view) {
    if (!(view.state.selection instanceof CellSelection)) return;

    this.content.attrs = this.getAttrsFromView(view);
  }

  destroy() {
    this.provider.destroy();
    this.content.remove();
  }

  getAttrsFromView(view) {
    const { doc, selection } = view.state;
    const isRow = selection?.isRowSelection?.();
    const isCol = selection?.isColSelection?.();
    const isWholeTable = isRow && isCol;
    const isAny = isRow || isCol;
    const isHeading = isRow && doc.nodeAt(selection?.$headCell?.pos)?.type?.name === 'table_header';

    return {
      isRow,
      isCol,
      isWholeTable,
      isAny,
      isHeading,
    };
  }
}
