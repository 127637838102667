import { remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    if (!this.url) {
      return;
    }

    if (this.disableTurbo) {
      window.location = this.url;
      return;
    }

    window.Turbo.visit(this.url, { action: this.action });
    remove(this.element);
  }

  get disableTurbo() {
    return this.element.dataset.disableTurbo === 'true';
  }

  get url() {
    if (this.element.dataset.url) {
      return this.element.dataset.url;
    }

    return window.location.href;
  }

  get action() {
    return this.element.dataset.action;
  }
}
