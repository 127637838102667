import I18n from '@/fuse/javascript/i18n';
import { capitalizeFirstLetter, isObject } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = [
    'payButton',
    'formFieldNumber',
    'formFieldExpirationDate',
    'formFieldCvv',
    'formLabelNumber',
    'formLabelExpirationDate',
    'formLabelCvv',
    'paymentResult',
    'errors',
    'errorMessageTemplate',
  ];

  connect() {
    if (this.isTurboPreview || this.element.dataset.initialized === 'true') return;

    this.element.dataset.initialized = true;

    this.saveCvvLabelContent();
    this.setupForm();
  }

  // Form setup and initialization

  setupForm() {
    this.disablePayButton();
    this.setupBraintreeComponents()
      .then(this.initializeBraintreeFields.bind(this))
      .catch(this.handleBraintreeSetupError.bind(this));
  }

  async setupBraintreeComponents() {
    const promises = [this.setupBraintreeHostedFields()];

    if (this.verifyWithThreeDS) {
      promises.push(this.setupBraintreeThreeDS());
    }

    return Promise.all(promises);
  }

  setupBraintreeHostedFields() {
    return braintree.hostedFields.create({
      authorization: this.braintreeClientToken,
      styles: this.hostedFieldsStyles,
      fields: this.hostedFieldsConfig,
    });
  }

  setupBraintreeThreeDS() {
    return braintree.threeDSecure.create({
      authorization: this.braintreeClientToken,
      version: 2,
      cardinalSDKConfig: { maxRequestRetries: 3 },
    });
  }

  initializeBraintreeFields(instances) {
    [this.hostedFields, this.threeDS] = instances;

    this.setupHostedFieldsListeners();
    this.enablePayButton();
  }

  setupHostedFieldsListeners() {
    this.hostedFields.on('focus', this.focusChangedHostedField.bind(this));
    this.hostedFields.on('blur', this.focusChangedHostedField.bind(this));
    this.hostedFields.on('validityChange', this.validityChangedHostedField.bind(this));
    this.hostedFields.on('cardTypeChange', this.cardTypeChangedHostedField.bind(this));
    this.hostedFields.on('inputSubmitRequest', this.triggerSubmit.bind(this));
  }

  // Form validation and submission

  validateHostedFields(event) {
    const state = this.hostedFields.getState();
    const isValid = Object.keys(state.fields).every((key) => {
      const field = state.fields[key];

      if (!field.isValid) {
        this.addFieldError(key, true);
        return false;
      }

      return true;
    });

    if (!isValid) event.preventDefault();
  }

  async createNoncePayload(event) {
    const { resume, cancel } = event.detail.intercept();
    let payload;

    try {
      payload = await this.hostedFields.tokenize(this.braintreeHostedFieldsTokenizeOptions);

      if (this.verifyWithThreeDS) {
        payload = await this.threeDS.verifyCard(this.braintreeThreeDSVerifyCardOptions(payload));
      }
    } catch (err) {
      this._showPaymentError(err);
      cancel();

      return;
    }

    if (payload) {
      event.detail.fetchOptions.body.append('payment_method_nonce', payload.nonce);
      resume();
    } else {
      cancel();
    }
  }

  // Error handling and display

  addFieldError(key, withMessage) {
    let message = '';

    if (withMessage) {
      if (key === 'number') {
        message = I18n.t('fuse.form.errors.payment.invalid_card_number');
      } else if (key === 'cvv') {
        message = I18n.t('fuse.form.errors.payment.invalid_cvv');
      } else if (key === 'expirationDate') {
        message = I18n.t('fuse.form.errors.payment.invalid_expiration_date');
      }
    }

    this.addErrors(message, [key]);
  }

  addErrors(message, elementKeys = []) {
    if (elementKeys.length) {
      this.handleFieldErrors(message, elementKeys);
    } else {
      this.handleGeneralError(message);
    }
  }

  handleFieldErrors(message, elementKeys) {
    this.errorsTarget.hidden = true;
    this.errorsTarget.firstElementChild.nextElementSibling.innerHTML = '';
    this.focusFirstInvalidHostedField();

    for (const key of elementKeys) {
      const formFieldTarget = this[`formField${capitalizeFirstLetter(key)}Target`];
      const formFieldController = this.findControllerOnElement(formFieldTarget, { controller: 'fuse--form-field' });

      formFieldController?.setValidity?.(message === '' ? null : 'error', message);
    }
  }

  handleGeneralError(message) {
    if (message === '') {
      this.errorsTarget.hidden = true;
      this.errorsTarget.firstElementChild.nextElementSibling.innerHTML = '';
    } else {
      const messageHTML = this.errorMessageTemplateTarget.innerHTML.replace('{content}', message);

      this.errorsTarget.firstElementChild.nextElementSibling.insertAdjacentHTML('beforeend', messageHTML);
      this.errorsTarget.hidden = false;
    }
  }

  removeFieldError(key) {
    const formFieldTarget = this[`formField${capitalizeFirstLetter(key)}Target`];
    const formFieldController = this.findControllerOnElement(formFieldTarget, { controller: 'fuse--form-field' });

    formFieldController?.setValidity(null, null);
  }

  removeErrors() {
    this.paymentResultTarget.innerHTML = '';
    this.errorsTarget.hidden = true;
    this.errorsTarget.firstElementChild.nextElementSibling.innerHTML = '';

    for (const key of ['number', 'expirationDate', 'cvv']) {
      const formFieldTarget = this[`formField${capitalizeFirstLetter(key)}Target`];
      const formFieldController = this.findControllerOnElement(formFieldTarget, { controller: 'fuse--form-field' });

      formFieldController?.setValidity?.(null, null);
    }
  }

  // Event handlers

  handleBraintreeSetupError(error) {
    console.warn('Braintree setup error:', error);

    this.addErrors(I18n.t('fuse.form.errors.payment.server.braintree_error'));
    this.reportErrorToSlidesLive(error);
  }

  focusChangedHostedField(event) {
    const { emittedBy, fields } = event;
    const formFieldTarget = this[`formField${capitalizeFirstLetter(emittedBy)}Target`];
    const formLabelTarget = this[`formLabel${capitalizeFirstLetter(emittedBy)}Target`];

    formFieldTarget.classList.toggle('active', fields[emittedBy].isFocused);

    if (fields[emittedBy].isFocused) formLabelTarget.click();
  }

  validityChangedHostedField(event) {
    const { emittedBy, fields } = event;
    const field = fields[emittedBy];

    if (!field.isValid) {
      this.addFieldError(emittedBy, true);
    } else {
      this.removeFieldError(emittedBy);

      if (!field.isPotentiallyValid) {
        this.focusNextField(emittedBy);
      }
    }
  }

  cardTypeChangedHostedField(event) {
    const { cards, fields } = event;
    const card = cards[0];

    if (!card || !card.supported || fields.number.isEmpty) {
      this.clearCardType();
    } else {
      this.element.dataset.cardType = card.type;
      this.formLabelCvvTarget.textContent = card.code.name;
    }
  }

  // Error handling methods

  showServerErrors(event) {
    const response = event.detail[0];

    if (response.braintree_errors) {
      this.handleBraintreeErrors(response.braintree_errors);
    } else if (response.errors) {
      response.errors.forEach((error) => this.addErrors(error));
    } else {
      this.addErrors(I18n.t('fuse.form.errors.payment.server.braintree_error'));
    }
  }

  handleBraintreeErrors(braintreeErrors) {
    if (braintreeErrors.errors) {
      braintreeErrors.errors.forEach((error) => {
        this.addErrors(`${error.message} [${error.code}, ${error.attribute}]`);
      });
    }

    if (braintreeErrors.processor_declined) {
      const error = braintreeErrors.processor_declined;
      this.addErrors(
        `${error.processor_response_text} [${error.processor_response_code}, ${error.processor_response_type}]`,
      );
    }

    if (braintreeErrors.settlement_declined) {
      const error = braintreeErrors.settlement_declined;
      this.addErrors(`${error.processor_settlement_response_text} [${error.processor_settlement_response_code}]`);
    }

    if (braintreeErrors.gateway_rejected) {
      this.addErrors(braintreeErrors.gateway_rejected.gateway_rejection_reason);
    }

    if (braintreeErrors.failed) {
      this.addErrors(I18n.t('fuse.form.errors.payment.failed'));
    }

    if (braintreeErrors.unknown_status) {
      this.addErrors(
        I18n.t('fuse.form.errors.payment.unknown_status', { status: braintreeErrors.unknown_status.status }),
      );
    }
  }

  _showPaymentError(err) {
    console.warn('Payment error:', err);

    this.reportErrorToSlidesLive(err);

    const errorHandlers = {
      HOSTED_FIELDS_FIELDS_EMPTY: () => this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.fields_empty')),
      HOSTED_FIELDS_FIELDS_INVALID: () => {
        if (err.details && err.details.invalidFields) {
          Object.keys(err.details.invalidFields).forEach((key) => this.addFieldError(key, true));
        } else {
          this.addErrors('', ['number', 'expirationDate', 'cvv']);
        }
      },
      HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE: () => this.addErrors(''),
      HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.tokenization_cvv_verification_failed'), ['cvv']),
      HOSTED_FIELDS_FAILED_TOKENIZATION: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.failed_tokenization')),
      HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.hosted_fields.tokenization_network_error')),
      THREEDS_AUTHENTICATION_IN_PROGRESS: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.3ds.authentication_in_progress')),
      THREEDS_MISSING_VERIFY_CARD_OPTION: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.3ds.missing_verify_card_option')),
      THREEDS_JWT_AUTHENTICATION_FAILED: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.3ds.jwt_authentication_failed')),
      THREEDS_LOOKUP_TOKENIZED_CARD_NOT_FOUND_ERROR: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.3ds.lookup_tokenized_card_not_found')),
      THREEDS_LOOKUP_VALIDATION_ERROR: () => {
        if (err.details?.originalError?.details?.originalError?.error?.message) {
          this.addErrors(err.details.originalError.details.originalError.error.message);
        }
        this.addErrors(I18n.t('fuse.form.errors.payment.3ds.lookup_validation_error'));
      },
      THREEDS_LOOKUP_ERROR: () => this.addErrors(I18n.t('fuse.form.errors.payment.3ds.lookup_error')),
      THREEDS_VERIFY_CARD_CANCELED_BY_MERCHANT: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.3ds.verify_card_canceled_by_merchant')),
      THREEDS_INLINE_IFRAME_DETAILS_INCORRECT: () =>
        this.addErrors(I18n.t('fuse.form.errors.payment.3ds.inline_iframe_details_incorrect')),
    };

    if (errorHandlers[err.code]) {
      errorHandlers[err.code]();

      return;
    }

    this.addErrors(I18n.t('fuse.form.errors.payment.unknown_error', { error: JSON.stringify(err) }));
  }

  // Utility methods

  clearCardType() {
    this.element.dataset.cardType = '';
    this.formLabelCvvTarget.textContent = this.formLabelCvvTarget.dataset.originalContent;
  }

  focusNextField(currentField) {
    const nextField = currentField === 'number' ? 'expirationDate' : 'cvv';

    this.hostedFields.focus(nextField);
  }

  triggerSubmit() {
    if (this.element.requestSubmit) {
      this.element.requestSubmit();
    } else {
      const submitButton = this.element.querySelector('[type="submit"]');

      if (submitButton) submitButton.click();
    }
  }

  reportErrorToSlidesLive(error) {
    fetch(this.element.dataset.braintreeErrorUrl, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_agent: navigator.userAgent,
        error: JSON.stringify(error),
      }),
    });
  }

  // Helper methods

  saveCvvLabelContent() {
    if (this.hasFormLabelCvvTarget && !this.formLabelCvvTarget.dataset.originalContent) {
      this.formLabelCvvTarget.dataset.originalContent = this.formLabelCvvTarget.textContent;
    }
  }

  disablePayButton() {
    this.payButtonTarget.disabled = true;
  }

  enablePayButton() {
    this.payButtonTarget.disabled = false;
  }

  focusFirstInvalidHostedField() {
    const fieldsState = this.hostedFields.getState().fields;
    const focusOrder = ['number', 'expirationDate', 'cvv'];

    if (fieldsState.number.isFocused || fieldsState.expirationDate.isFocused || fieldsState.cvv.isFocused) return;

    for (const field of focusOrder) {
      if (!fieldsState[field].isValid && !fieldsState[field].isFocused) {
        this.hostedFields.focus(field);
        break;
      }
    }
  }

  clientInfoValueForInput(name) {
    const input = this.element.querySelector(`input[name="${name}"], select[name="${name}"]`);

    return input ? input.value : '';
  }

  removeEmptyValues(object) {
    for (const key of Object.keys(object)) {
      if (isObject(object[key])) {
        object[key] = this.removeEmptyValues(object[key]);
      }

      if (!object[key] || object[key] === '' || (isObject(object[key]) && Object.keys(object[key]).length === 0)) {
        delete object[key];
      }
    }

    return object;
  }

  braintreeThreeDSVerifyCardOptions(payload) {
    const clientInfo = this.clientInfo;
    const normalizedCustomerName = clientInfo.customer_name.normalize
      ? clientInfo.customer_name.normalize('NFKD').replace(/[^a-z0-9 ]/gi, '')
      : clientInfo.customer_name.replace(/[^a-z0-9 ]/gi, '');

    const [clientGivenName, ...clientSurnameParts] = normalizedCustomerName.split(' ');
    const clientSurname = clientSurnameParts.join(' ');

    return {
      onLookupComplete: (data, next) => next(),
      amount: this.element.dataset.amount,
      nonce: payload.nonce,
      bin: payload.details.bin,
      email: clientInfo.email,
      billingAddress: {
        givenName: clientGivenName,
        surname: clientSurname,
        streetAddress: clientInfo.address.street_1,
        extendedAddress: clientInfo.address.street_2,
        locality: clientInfo.address.city,
        region: clientInfo.address.state,
        postalCode: clientInfo.address.postal_code,
        countryCodeAlpha2: clientInfo.address.country,
      },
    };
  }

  get hostedFieldsStyles() {
    return {
      input: {
        color: 'rgba(255, 255, 255, 0.89)',
        'font-size': '14px',
        'font-family': 'monospace',
        padding: '0 20px 6px',
      },
      '::-webkit-input-placeholder': { color: 'rgba(255, 255, 255, 0.5)' },
      ':-moz-placeholder': { color: 'rgba(255, 255, 255, 0.5)' },
      '::-moz-placeholder': { color: 'rgba(255, 255, 255, 0.5)' },
      ':-ms-input-placeholder': { color: 'rgba(255, 255, 255, 0.5)' },
    };
  }

  get hostedFieldsConfig() {
    return {
      number: {
        selector: '#ccNumber',
        placeholder: this.formFieldNumberTarget.dataset.placeholder,
      },
      cvv: {
        selector: '#ccCvv',
        placeholder: this.formFieldCvvTarget.dataset.placeholder,
      },
      expirationDate: {
        selector: '#ccExpirationDate',
        placeholder: this.formFieldExpirationDateTarget.dataset.placeholder,
      },
    };
  }

  get braintreeClientToken() {
    return this.element.getAttribute('data-braintree-client-token');
  }

  get verifyWithThreeDS() {
    return this.element.getAttribute('data-braintree-require-three-ds') === 'true';
  }

  get braintreeHostedFieldsTokenizeOptions() {
    const clientInfo = this.clientInfo;
    const [clientGivenName, ...clientSurnameParts] = clientInfo.customer_name.split(' ');
    const clientSurname = clientSurnameParts.join(' ');

    return this.removeEmptyValues({
      cardholderName: clientInfo.customer_name,
      billingAddress: {
        company: clientInfo.company.name,
        firstName: clientGivenName,
        lastName: clientSurname,
        streetAddress: clientInfo.address.street_1,
        extendedAddress: clientInfo.address.street_2,
        locality: clientInfo.address.city,
        region: clientInfo.address.state,
        postalCode: clientInfo.address.postal_code,
        countryCodeAlpha2: clientInfo.address.country,
      },
    });
  }

  get clientInfo() {
    if (this.element.dataset.clientInfo && this.element.dataset.clientInfo !== '') {
      return JSON.parse(this.element.dataset.clientInfo);
    }

    return {
      customer_name: this.clientInfoValueForInput('billing[customer_name]'),
      email: this.clientInfoValueForInput('billing[email]'),
      address: {
        street_1: this.clientInfoValueForInput('billing[address][street_1]'),
        street_2: this.clientInfoValueForInput('billing[address][street_2]'),
        city: this.clientInfoValueForInput('billing[address][city]'),
        state: this.clientInfoValueForInput('billing[address][state]'),
        country: this.clientInfoValueForInput('billing[address][country]'),
        postal_code: this.clientInfoValueForInput('billing[address][postal_code]'),
      },
      company: {
        name: this.clientInfoValueForInput('billing[company][name]'),
        reg_no: this.clientInfoValueForInput('billing[company][reg_no]'),
        vat_reg_no: this.clientInfoValueForInput('billing[company][vat_reg_no]'),
      },
    };
  }
}
