import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = ['captcha', 'warning', 'error'];

  static values = {
    debug: {
      type: Boolean,
      default: false,
    },
    turnstileAction: {
      type: String,
      default: null,
    },
    preferredProvider: {
      type: String,
      default: 'cloudflare-turnstile',
    },
    formId: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: 'dark',
    },
    size: {
      type: String,
      default: 'normal',
    },
    turnstileAppearance: {
      type: String,
      default: 'always',
    },
    turnstileLanguage: {
      type: String,
      default: 'en',
    },
    cloudflareTurnstileSiteKey: {
      type: String,
      required: true,
    },
    googleRecaptchaSiteKey: {
      type: String,
      required: true,
    },
  };

  initialize() {
    this.pendingCaptchaPromises = [];
    this.clouadflareTurnstileWidgetId = undefined;
    this.googleRecaptchaWidgetId = undefined;

    // eslint-disable-next-line no-console
    if (this.debugValue) console.log('Initialize Captcha');
  }

  connect() {
    this.toggleParentFormButtons(false);

    // eslint-disable-next-line no-console
    if (this.debugValue) console.log('Connect Captcha');

    if (this.preferredProviderValue === 'google-recaptcha') {
      this.renderGoogleRecaptcha('connect');
    } else {
      this.renderCloudflareTurnstile('connect');
    }
  }

  disconnect() {
    if (this.clouadflareTurnstileWidgetId) {
      window.turnstile.remove(this.clouadflareTurnstileWidgetId);
    }

    this.clouadflareTurnstileWidgetId = undefined;
    this.googleRecaptchaWidgetId = undefined;

    this.captchaTarget.innerHTML = '';
  }

  resetCaptcha() {
    this.rejectPendingCaptchaPromises();
    this.resetCloudflareTurnstile();
    this.resetGoogleRecaptcha();
  }

  resetCloudflareTurnstile() {
    this.toggleParentFormButtons(false);

    if (this.debugValue) {
      // eslint-disable-next-line no-console
      console.log('Reset Cloudflare Turnstile', this.clouadflareTurnstileWidgetId);
    }

    if (this.clouadflareTurnstileWidgetId) {
      window.turnstile.reset(this.clouadflareTurnstileWidgetId);
    }
  }

  renderCloudflareTurnstileFromCallback() {
    if (this.preferredProviderValue !== 'cloudflare-turnstile') return;

    this.renderCloudflareTurnstile('callback');
  }

  renderCloudflareTurnstile(source) {
    if (this.debugValue) {
      // eslint-disable-next-line no-console
      console.log('Render Cloudflare Turnstile', source, this.clouadflareTurnstileWidgetId, !!window.turnstile);
    }

    if (this.clouadflareTurnstileWidgetId) return;

    if (!window.turnstile) {
      console.warn('Cloudflare Turnstile is not loaded.');
      return;
    }

    this.captchaTarget.innerHTML = '';
    this.clouadflareTurnstileWidgetId = window.turnstile.render(this.captchaTarget, {
      sitekey: this.cloudflareTurnstileSiteKeyValue,
      theme: this.themeValue,
      action: this.turnstileActionValue,
      language: this.turnstileLanguageValue,
      size: this.sizeValue,
      appearance: this.turnstileAppearanceValue,
      callback: (args) => {
        this.toggleParentFormButtons(true);

        this.resolvePendingCaptchaPromises(args);
        this.dispatch('success', { detail: args });
      },
      'error-callback': (args) => {
        this.dispatch('error', { detail: args });

        console.warn('Cloudflare Turnstile error:', args);

        this.renderGoogleRecaptcha('cloudflare-recaptcha-error');
      },
      'expired-callback': (args) => {
        this.toggleParentFormButtons(false);

        this.rejectPendingCaptchaPromises(args);
        this.dispatch('expired', { detail: args });
      },
      'before-interactive-callback': () => {
        // eslint-disable-next-line no-console
        console.log('Cloudflare Turnstile interactive challenge.');

        this.show();
      },
      'unsupported-callback': () => {
        console.warn('Unsupported browser for Cloudflare Turnstile bot verification.');

        this.renderGoogleRecaptcha('cloudflare-recaptcha-unsupported');
      },
    });

    if (this.debugValue) {
      // eslint-disable-next-line no-console
      console.log('Rendered Cloudflare Turnstile', this.clouadflareTurnstileWidgetId);
    }
  }

  resetGoogleRecaptcha() {
    this.toggleParentFormButtons(false);

    if (this.debugValue) {
      // eslint-disable-next-line no-console
      console.log('Reset Google reCaptcha', this.googleRecaptchaWidgetId);
    }

    if (this.googleRecaptchaWidgetId) {
      window.grecaptcha.reset(this.googleRecaptchaWidgetId);
    }
  }

  renderGoogleRecaptchaFromCallback() {
    if (this.preferredProviderValue !== 'google-recaptcha') return;

    this.renderGoogleRecaptcha('callback');
  }

  renderGoogleRecaptcha(source) {
    if (this.debugValue) {
      // eslint-disable-next-line no-console
      console.log('Render Google reCaptcha', source, this.googleRecaptchaWidgetId, !!window.grecaptcha);
    }

    if (this.googleRecaptchaWidgetId) return;

    if (!window.grecaptcha || !window.grecaptcha.render) {
      console.warn('Google reCaptcha is not loaded.');
      return;
    }

    this.captchaTarget.innerHTML = '';
    this.googleRecaptchaWidgetId = window.grecaptcha.render(this.captchaTarget, {
      sitekey: this.googleRecaptchaSiteKeyValue,
      theme: this.themeValue,
      size: this.sizeValue,
      callback: (args) => {
        this.toggleParentFormButtons(true);

        this.resolvePendingCaptchaPromises(args);
        this.dispatch('success', { detail: args });
      },
      'error-callback': (args) => {
        this.dispatch('error', { detail: args });

        console.warn('Turnstile error:', args);

        this.rejectPendingCaptchaPromises(args);
        this.showError(`Google reCaptcha bot verification error: ${JSON.stringify(args)}`);
      },
      'expired-callback': (args) => {
        this.toggleParentFormButtons(false);

        this.rejectPendingCaptchaPromises(args);
        this.dispatch('expired', { detail: args });
      },
    });

    if (this.debugValue) {
      // eslint-disable-next-line no-console
      console.log('Rendered Google reCaptcha', this.googleRecaptchaWidgetId);
    }
  }

  show() {
    this.element.hidden = false;
  }

  showWarning(message) {
    this.warningTarget.querySelector('[data-captcha-message]').textContent = message;
    this.warningTarget.hidden = false;
  }

  showError(message) {
    this.errorTarget.querySelector('[data-captcha-message]').textContent = message;
    this.errorTarget.hidden = false;
  }

  toggleParentFormButtons(enabled) {
    if (this.invisible) {
      return;
    }

    const parentForm = this.parentForm;
    if (!parentForm) return;

    const buttons = parentForm.querySelectorAll('button[type="submit"], input[type="submit"]');
    for (const button of buttons) {
      button.disabled = !enabled;
    }
  }

  resolvePendingCaptchaPromises(arg) {
    for (const { resolve } of this.pendingCaptchaPromises) {
      resolve(arg);
    }

    this.pendingCaptchaPromises = [];
  }

  rejectPendingCaptchaPromises(arg) {
    for (const { reject } of this.pendingCaptchaPromises) {
      reject(arg);
    }

    this.pendingCaptchaPromises = [];
  }

  executeInvisibleCaptcha() {
    if (!this.invisible) {
      return Promise.reject();
    }

    if (!isVariableDefinedNotNull(this.googleRecaptchaWidgetId)) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      this.pendingCaptchaPromises.push({ resolve, reject });
      window.grecaptcha.execute(this.googleRecaptchaWidgetId);
    });
  }

  get parentForm() {
    if (!this.formIdValue) return null;

    return document.getElementById(this.formIdValue);
  }

  get invisible() {
    return this.sizeValue === 'invisible';
  }
}
