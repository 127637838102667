import { runCallbacks, toUnderscore } from '@slideslive/fuse-kit/utils';

function defaultGetter(store, property) {
  return store[property];
}

function defaultSetter(store, property, value) {
  store[property] = value;
}

function defaultReducer() {}

function useStore(
  context,
  { getter = defaultGetter, setter = defaultSetter, reducer = defaultReducer, initialState = {} } = {},
) {
  let store;
  let skipChangeCallbacks = false;
  let resetRunning = false;
  const onChangeAnyCallbacks = [];
  const onChangeCallbacks = {};

  const state = {
    get: (_store, property) => {
      if (!(property in initialState)) {
        console.warn('[STIMULUS] trying to get store property that does not exist', property);

        return undefined;
      }

      return getter.bind(context)(_store, property);
    },
    set: (_store, property, value) => {
      if (!(property in initialState)) {
        console.warn('[STIMULUS] trying to set store property that was not defined before', property);

        return true;
      }

      // ToDo - think about forbidding setting state outside reducer
      const oldValue = _store[property];
      const setterReturnValue = setter.bind(context)(_store, property, value, _store[property]);
      const newValue = _store[property];

      if (!skipChangeCallbacks) {
        runCallbacks(onChangeCallbacks[property], newValue, oldValue);

        if (!resetRunning) {
          runCallbacks(onChangeAnyCallbacks, property, newValue, oldValue);
        }
      }

      return setterReturnValue ?? true;
    },
  };

  for (const property of Object.keys(initialState)) {
    if (['dispatch', 'reset', 'onChange'].includes(property)) {
      console.warn('[STIMULUS] trying to set reserved store property', property);
      initialState.delete(property);
    } else {
      // define property names as constants in the store and use them instead of strings to get errors
      // if trying to use property that does not exist in the store
      Object.defineProperty(initialState, toUnderscore(property).toUpperCase(), {
        value: property,
      });
    }
  }

  Object.defineProperty(initialState, 'onChange', {
    value: (propertyOrCallback, optionalCallback = null) => {
      if (typeof propertyOrCallback === 'function') {
        onChangeAnyCallbacks.push(propertyOrCallback.bind(context));
        return;
      }

      if (typeof propertyOrCallback === 'string' && typeof optionalCallback === 'function') {
        if (!onChangeCallbacks[propertyOrCallback]) {
          onChangeCallbacks[propertyOrCallback] = [];
        }

        onChangeCallbacks[propertyOrCallback].push(optionalCallback.bind(context));

        return;
      }

      console.warn('[STIMULUS] wrong store onChange callback', propertyOrCallback, optionalCallback);
    },
  });

  // helper function to reset store to its initial state with option to skip the onChange callbacks
  Object.defineProperty(initialState, 'reset', {
    value: ({ skipChangeCallback = false } = {}) => {
      const propertyNames = [];
      const oldValues = [];
      const newValues = [];

      skipChangeCallbacks = skipChangeCallback;
      resetRunning = true;

      for (const property of Object.keys(initialState)) {
        propertyNames.push(property);
        oldValues.push(store[property]);
        newValues.push(initialState[property]);

        store[property] = initialState[property];
      }

      if (!skipChangeCallbacks) {
        runCallbacks(onChangeAnyCallbacks, propertyNames, newValues, oldValues);
      }

      resetRunning = false;
      skipChangeCallbacks = false;
    },
  });

  Object.defineProperty(initialState, 'dispatch', {
    value: (action, payload) => reducer.bind(context)(store, action, payload),
  });

  store = new Proxy(initialState, state);

  return store;
}

export default useStore;
