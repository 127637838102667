import { createElementFromHTML, remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['content'];
  }

  initialize() {
    this.listData = [];
    this.defaultItemHeight = 0;
    this.containerHeight = 0;
    this.firstVisible = 0;
    this.lastVisible = 0;

    this.props = {
      data: [],
      indexesToRender: [],
      renderer: () => {
        throw new Error('You need to implement renderer method.');
      },
    };
  }

  initializeListData() {
    this.listData = this.data.map(() => ({
      height: null,
      element: null,
    }));
  }

  initializeIndexesToRender() {
    this.indexesToRender = this.data.map((_, index) => index);
  }

  sumHeightFromArray(array, initialValue = 0) {
    return array.reduce(
      (acc, dataIndex) => acc + (this.listData[dataIndex].height || this.defaultItemHeight),
      initialValue,
    );
  }

  renderListItem(data) {
    return createElementFromHTML(this.renderer(data));
  }

  renderList() {
    const firstRenderedItemIndex = Math.max(0, this.firstVisible - 2);
    const lastRenderedItemIndex = Math.min(this.itemsCount - 1, this.lastVisible + 2);

    if (
      this.contentTarget.childElementCount > 0 &&
      this.contentTarget.firstElementChild === this.listData[this.indexesToRender[firstRenderedItemIndex]].element &&
      this.contentTarget.lastElementChild === this.listData[this.indexesToRender[lastRenderedItemIndex]].element
    ) {
      return;
    }

    let itemOffset = this.sumHeightFromArray(this.indexesToRender.slice(0, firstRenderedItemIndex));

    this.contentTarget.innerHTML = '';

    for (let i = firstRenderedItemIndex; i <= lastRenderedItemIndex; i++) {
      const dataIndex = this.indexesToRender[i];
      const itemData = this.listData[dataIndex];
      const itemElement = itemData.element || this.renderListItem(this.data[dataIndex]);

      itemElement.style.transform = `translate3d(0, ${itemOffset}px, 0)`;
      this.contentTarget.insertAdjacentElement('beforeend', itemElement);

      if (!itemData.element) {
        itemData.element = itemElement;
      }

      itemData.height = itemElement.offsetHeight;
      itemOffset += itemData.height;
    }

    const listHeight = this.sumHeightFromArray(this.indexesToRender.slice(lastRenderedItemIndex + 1), itemOffset);

    if (listHeight !== Number(this.contentTarget.style.height.replace(/\D/g, ''))) {
      this.contentTarget.style.height = `${listHeight}px`;
    }
  }

  updateVisibleItems() {
    let newFirstVisible = null;
    let newLastVisible = null;
    let itemOffset = 0;

    for (let i = 0; i < this.itemsCount; i++) {
      const dataIndex = this.indexesToRender[i];
      const itemData = this.listData[dataIndex];

      itemOffset += itemData.height || this.defaultItemHeight;

      if (newFirstVisible === null && itemOffset > this.element.scrollTop) {
        newFirstVisible = i;
      }

      if (newLastVisible === null && itemOffset > this.element.scrollTop + this.containerHeight) {
        newLastVisible = i;
        break;
      }
    }

    if (newFirstVisible === null) {
      newFirstVisible = 0;
    }

    if (newLastVisible === null) {
      newLastVisible = this.itemsCount - 1;
    }

    this.firstVisible = newFirstVisible;
    this.lastVisible = newLastVisible;
  }

  updateDefaultItemHeight() {
    let removeAfter = false;
    let firstItemElement = this.contentTarget.firstElementChild;

    if (!firstItemElement) {
      removeAfter = true;
      firstItemElement = this.listData[0].element || this.renderListItem(this.data[0]);
      this.contentTarget.insertAdjacentElement('beforeend', firstItemElement);
    }

    this.defaultItemHeight = firstItemElement.offsetHeight;
    this.contentTarget.style.height = `${this.itemsCount * this.defaultItemHeight}px`;

    if (removeAfter) {
      remove(firstItemElement);
    }
  }

  updateContainerHeight() {
    this.containerHeight = this.element.offsetHeight;
  }

  reloadList() {
    if (this.itemsCount === 0) {
      this.contentTarget.innerHTML = '';
      this.contentTarget.style.height = null;

      return;
    }

    this.updateDefaultItemHeight();

    if (this.defaultItemHeight === 0) {
      this.contentTarget.innerHTML = '';
      this.contentTarget.style.height = null;

      return;
    }

    this.element.scrollTop = 0;

    this.updateContainerHeight();
    this.updateVisibleItems();
    this.renderList();
  }

  get itemsCount() {
    return this.indexesToRender.length;
  }

  get data() {
    return this.props.data;
  }

  set data(value) {
    this.props.data = value;

    this.initializeListData();
    this.initializeIndexesToRender();
  }

  set indexesToRender(value) {
    if (value === null) {
      value = this.data.map((_, index) => index);
    }

    this.props.indexesToRender = value;

    this.reloadList();
  }

  get indexesToRender() {
    return this.props.indexesToRender;
  }

  get renderer() {
    return this.props.renderer;
  }

  set renderer(value) {
    this.props.renderer = value;
  }
}
