import {
  animationsComplete,
  checkDelayedResult,
  focusFirstFocusableTarget,
  hide,
  show,
} from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';
import stimulus, { FUSE_MODAL_CONTROLLER } from 'plugins/stimulus';

const DEFAULT_ANIMATE_IN_CLASSES = ['tw-animate-zoom-in'];
const DEFAULT_ANIMATE_OUT_CLASSES = ['tw-animate-zoom-out'];

export default class extends ApplicationController {
  static targets = ['content', 'spinner'];

  initialize() {
    this.observer = new MutationObserver(this._handleMutations.bind(this));
    this.hadInitialContent = false;
    this.loading = false;
    this.abortController = null;
    this.trigger = null;
    this.instanceOptions = {};
  }

  connect() {
    this._startContentObserve();
    this._initializeState();
  }

  disconnect() {
    this.destroy();
  }

  destroy() {
    this._endContentObserve();
  }

  open() {
    this.dispatch('open', { prefix: 'dialog', bubbles: false });
  }

  close() {
    this.dispatch('close', { prefix: 'dialog', bubbles: false });
  }

  _initializeState() {
    this.hadInitialContent = this.contentTarget.innerHTML.trim() !== '';

    if (this.hadInitialContent) {
      this.showContent(true);
    } else {
      this.showSpinner(true);
    }
  }

  _startContentObserve() {
    this.observer?.observe(this.contentTarget, { childList: true });
  }

  _endContentObserve() {
    this.observer?.disconnect();
  }

  _handleMutations(mutations) {
    mutations.forEach(async (mutation) => {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        this.contentLoaded();
      }
    });
  }

  propagateDialogEvent(event) {
    event.stopPropagation();

    const name = event.type.split(':').pop();

    this._runCallback(name);
  }

  resetInstance() {
    this.trigger = null;
    this.instanceOptions = {};
  }

  resetContent() {
    if (this.hadInitialContent) return;

    this.showSpinner(true);
    this.clearContent();
  }

  initContent() {
    if (this.hadInitialContent) {
      this.contentLoaded(true);

      return;
    }

    this.fetchContent();
  }

  async fetchContent() {
    if (!this.instanceOptions.url) return;

    this._runCallback('fetching');

    this.abortController = new AbortController();

    try {
      const url = this._buildUrl();
      const config = this._buildFetchConfig();
      const response = await fetch(url, config);

      await this._handleResponse(response);
      this._runCallback('fetched');
    } catch (error) {
      this._handleFetchError(error);
    }
  }

  _buildUrl() {
    let url = this.instanceOptions.url;

    if (this.urlParams) {
      url += `${url.includes('?') ? '&' : '?'}${this.urlParams}`;
    }

    if (this.instanceOptions.urlParams) {
      url += `${url.includes('?') ? '&' : '?'}${this.instanceOptions.urlParams}`;
    }

    return url;
  }

  _buildFetchConfig() {
    return {
      method: this.instanceOptions.requestMethod || 'GET',
      headers: this._buildHeaders(),
      body: this.instanceOptions.requestBody,
      credentials: 'include',
      signal: this.abortController.signal,
    };
  }

  _buildHeaders() {
    const headers = {
      Accept: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
      'X-Requested-With': 'XMLHttpRequest',
    };

    const { requestContentType, requestCsrfToken } = this.instanceOptions;

    if (requestContentType) {
      headers['Content-Type'] = requestContentType;
    }

    if (requestCsrfToken) {
      headers['X-CSRF-Token'] = requestCsrfToken;
    }

    return headers;
  }

  async _handleResponse(response) {
    const contentType = response.headers.get('content-type');

    if (contentType === 'application/json') {
      const json = await response.json();

      await this._fetchFromDelayedResult(this.instanceOptions.url, json);
    } else {
      const content = await response.text();

      this.setContent(content);
    }
  }

  _handleFetchError(error) {
    this.abortController = null;

    if (error.name === 'AbortError') {
      this._runCallback('fetch-aborted');

      return;
    }

    this.close();
    console.warn(`[${this.identifier}] fetchContent error:`, error);
    this._runCallback('fetch-error', { data: { error } });
  }

  async _fetchFromDelayedResult(url, response) {
    const delayedResultId = response.delayed_result_id;

    if (!delayedResultId) {
      console.warn('Loading modal from', url, ' from delayed result failed: No delayed result ID.');
      this._runCallback('fetch-error');
      this.close();

      return;
    }

    const delayedResultUrl = `${this.delayedResultUrl}?id=${delayedResultId}`;

    checkDelayedResult(delayedResultUrl, this._handleDelayedResult.bind(this, url), 500);
  }

  _handleDelayedResult(url, result) {
    if (result.success) {
      this.setContent(result.html);
      this.contentLoaded();
    } else if (result.errors) {
      console.warn('Loading modal from', url, 'from delayed result failed:', result.errors);
      this._runCallback('fetch-error');
      this.close();
    }
  }

  async contentLoaded(noAnimation = false) {
    await animationsComplete(this.element);
    await this.showContent(noAnimation);

    if (this.instanceOptions.autofocus) {
      if (!document.activeElement || !this.contentTarget.contains(document.activeElement)) {
        focusFirstFocusableTarget(this.contentTarget, { preventScroll: true });
      }
    }
  }

  triggerReloadFromEvent({
    detail: { name, options = null, restoreFocus = false, disableFormElements = false, noAnimation = false },
  }) {
    if (name !== this.name) return;

    if (options) {
      const { url, requestMethod, requestContentType, requestCsrfToken, requestBody } = this.instanceOptions;

      this.instanceOptions = {
        url,
        requestMethod,
        requestContentType,
        requestCsrfToken,
        requestBody,
        ...options,
      };
    }

    this.reloadContent({ restoreFocus, disableFormElements, noAnimation });
  }

  triggerOpenFromEvent({ detail: { name, trigger = null, options = {} } }) {
    if (name !== this.name) return;

    if (this.opened) {
      this._handleReopenFromEvent(trigger, options);
    } else {
      this._openFromEvent(trigger, options);
    }
  }

  _handleReopenFromEvent(trigger, options) {
    this.trigger = trigger;
    this.instanceOptions = options;

    this.reloadContent();
  }

  _openFromEvent(trigger, options) {
    this.trigger = trigger;
    this.instanceOptions = options;

    if (this._shouldUpdateBrowserHistory()) {
      this._updateBrowserHistory();
    }

    if (this.isOpen) {
      this.reloadContent();
    } else {
      this.open();
    }
  }

  _shouldUpdateBrowserHistory() {
    const { withHistory, url, requestMethod } = this.instanceOptions;

    return withHistory && url && (!requestMethod || requestMethod === 'GET');
  }

  _updateBrowserHistory() {
    const historyState = {
      modal: this.name,
      instanceOptions: this.instanceOptions,
      previousState: window.history.state,
      previousUrl: window.location.href,
    };

    window.history.pushState(historyState, undefined, this.instanceOptions.url);
  }

  triggerFromPopstate(event) {
    if (!event.state) return;

    if (event.state.modal === this.name) {
      this.instanceOptions = event.state.instanceOptions;

      this.open();
    } else {
      this.close();
    }
  }

  resetHistoryState() {
    const currentState = window.history.state;

    if (currentState?.modal === this.name) {
      window.history.pushState(currentState.previousState, null, currentState.previousUrl);
    }
  }

  triggerCloseFromEvent({ detail: { name } }) {
    if (name !== this.name) return;

    this.close();
  }

  abortLoading() {
    if (!this.abortController) return;

    try {
      this.abortController.abort();
    } finally {
      this.abortController = null;
    }
  }

  async animateInTarget(target) {
    target.classList.add(...DEFAULT_ANIMATE_IN_CLASSES);
    show(target, { useHiddenAttr: true });
    await animationsComplete(target);
    target.classList.remove(...DEFAULT_ANIMATE_IN_CLASSES);
  }

  async animateOutTarget(target) {
    target.classList.add(...DEFAULT_ANIMATE_OUT_CLASSES);
    await animationsComplete(target);
    hide(target, { useHiddenAttr: true });
    target.classList.remove(...DEFAULT_ANIMATE_OUT_CLASSES);
  }

  async showContent(noAnimation = false) {
    await animationsComplete(this.contentTarget);
    this.hideSpinner(noAnimation);

    if (!this.contentTarget.hidden) return;

    if (noAnimation) {
      show(this.contentTarget, { useHiddenAttr: true });

      return;
    }

    await this.animateInTarget(this.contentTarget);
  }

  async hideContent(noAnimation = false) {
    await animationsComplete(this.contentTarget);

    if (this.contentTarget.hidden) return;

    if (noAnimation) {
      hide(this.contentTarget, { useHiddenAttr: true });

      return;
    }

    await this.animateOutTarget(this.contentTarget);
  }

  async showSpinner(noAnimation = false) {
    await animationsComplete(this.spinnerTarget);
    this.hideContent(noAnimation);

    if (!this.spinnerTarget.hidden) return;

    if (noAnimation) {
      show(this.spinnerTarget, { useHiddenAttr: true });

      return;
    }

    await this.animateInTarget(this.spinnerTarget);
  }

  async hideSpinner(noAnimation = false) {
    await animationsComplete(this.spinnerTarget);

    if (this.spinnerTarget.hidden) return;

    if (noAnimation) {
      hide(this.spinnerTarget, { useHiddenAttr: true });

      return;
    }

    await this.animateOutTarget(this.spinnerTarget);
  }

  setContent(content) {
    if (typeof content !== 'string') {
      console.warn('Invalid content type provided to setContent');

      return;
    }

    this.contentTarget.innerHTML = content;
  }

  clearContent() {
    this.setContent('');
  }

  changeContent({ target: { action, target: targetId } }) {
    if (action !== 'remove') return;

    if (this._shouldCloseOnContentChange(targetId)) {
      this.close();
    }
  }

  async reloadContent({ restoreFocus = true, disableFormElements = false, noAnimation = false } = {}) {
    let activeElementId;
    if (restoreFocus && document.activeElement && this.contentTarget.contains(document.activeElement)) {
      activeElementId = document.activeElement.id;
    }

    if (disableFormElements) {
      for (const input of this.contentTarget.querySelectorAll('input, select, button')) {
        input.disabled = true;
      }
    }

    if (!noAnimation) {
      this.showSpinner();
    }

    await this.fetchContent();

    const elementToFocus = document.getElementById(activeElementId);
    if (elementToFocus && this.contentTarget.contains(elementToFocus)) {
      elementToFocus.focus({ preventScroll: true });
    }
  }

  _shouldCloseOnContentChange(targetId) {
    if (!targetId) return false;

    return targetId === this.contentTarget?.id || targetId === this.contentTarget?.firstElementChild?.id;
  }

  accept(closeModal = true, { data = undefined } = {}) {
    if (closeModal) {
      this.close();
    }

    this._runCallback('accept', { data });
  }

  reject(closeModal = true, { data = undefined } = {}) {
    if (closeModal) {
      this.close();
    }

    this._runCallback('reject', { data });
  }

  _runCallback(name, { fireModalEvent = true, data = {} } = {}) {
    if (typeof this.instanceOptions[name] === 'function') {
      this.instanceOptions[name].call(this, this);
    }

    if (fireModalEvent) {
      this.dispatch(name, { detail: { name: this.name, data } });
    }

    if (this.trigger) {
      this.dispatch(name, { target: this.trigger, bubbles: false, detail: data });
    }
  }

  get name() {
    return this.element.dataset.modalName;
  }

  get urlParams() {
    return this.element.dataset.modalUrlParams;
  }

  get delayedResultUrl() {
    return this.element.dataset.delayedResultUrl || 'https://slideslive.com/-/delayed_result';
  }

  set persistent(value) {
    stimulus.setValue({ [FUSE_MODAL_CONTROLLER]: { persistent: value } });
  }

  set closeOnBgClick(value) {
    stimulus.setValue({ [FUSE_MODAL_CONTROLLER]: { closeOnBgClick: value } });
  }

  get isOpen() {
    return this.element.hasAttribute('open');
  }
}
