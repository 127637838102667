import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['spinnerTemplate', 'contentFrame'];
  }

  initialize() {
    this.loadContentTimeout = null;
    this.lastContentLoadProps = null;
    this.requestsCounter = 0;
  }

  showSpinner() {
    if (this.contentFrameTarget.innerHTML === this.spinnerTemplateTarget.innerHTML) return;

    this.contentFrameTarget.innerHTML = this.spinnerTemplateTarget.innerHTML;
  }

  reset() {
    this.requestsCounter = 0;

    if (this.contentSrc) {
      this.showSpinner();
      this.contentFrameTarget.src = this.contentSrc;

      const url = new URL(this.contentSrc);

      this.contentFrameTarget.id = `${url.searchParams.get('id_prefix')}Content`;
    } else {
      this.contentFrameTarget.innerHTML = '';
    }

    if (this.loadContentTimeout) {
      clearTimeout(this.loadContentTimeout);
      this.loadContentTimeout = null;
    }
  }

  discardRenderWhenLoadScheduled(event) {
    if (this.loadContentTimeout) {
      event.detail.render = () => {};
    }
  }

  discardOldFrameAndScheduleNewLoad(event) {
    event.preventDefault();

    if (
      !this.loadContentTimeout &&
      this.lastContentLoadProps &&
      this.contentFrameTarget.src !== this.lastContentLoadProps.src
    ) {
      this.loadContentFromEvent({ detail: this.lastContentLoadProps });
    }
  }

  clearContentSrcAndLastContentLoadProps() {
    this.contentFrameTarget.src = '';
    this.lastContentLoadProps = null;
  }

  loadContentFromEvent({ detail: { query, type } }) {
    if (this.disabled || !this.contentSrc) {
      return;
    }

    if (this.loadContentTimeout) {
      clearTimeout(this.loadContentTimeout);
    }

    this.loadContentTimeout = setTimeout(() => {
      this.loadContentTimeout = null;

      this.loadContent({ query, type });
    }, 200);
  }

  loadContent({ query, type }) {
    if (this.disabled || !this.contentSrc) {
      return;
    }

    const url = new URL(this.contentSrc);

    if (query) {
      url.searchParams.set('query', query);
      url.searchParams.set('type', type);
    }

    const idPrefix = `${url.searchParams.get('id_prefix')}${++this.requestsCounter}`;
    url.searchParams.set('id_prefix', idPrefix);

    this.lastContentLoadProps = {
      query,
      type,
      src: url.toString(),
    };

    this.contentFrameTarget.id = `${idPrefix}Content`;
    this.contentFrameTarget.src = url;
  }

  get contentSrc() {
    return this.element.dataset.contentSrc;
  }

  get disabled() {
    return this.contentFrameTarget.disabled;
  }

  set disabled(value) {
    this.contentFrameTarget.disabled = value;
  }
}
