import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { toUnderscore } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

import MARKER from './images/pin.png';
import mapStyle from './map_style';

export default class extends ApplicationController {
  static get values() {
    return {
      data: {
        type: Array,
        default: [],
      },
      hideControls: {
        type: Boolean,
        default: false,
      },
      hideDetails: {
        type: Boolean,
        default: false,
      },
      defaultPosition: {
        type: Array,
        default: [29.9064546, -44.7204451],
      },
      defaultZoom: {
        type: Number,
        default: 3,
      },
      maskMarkerCluster: {
        type: Boolean,
        default: false,
      },
    };
  }

  static get targets() {
    return [
      'map',
      'actionsList',
      'settings',
      'list',
      'markersSwitch',
      'markerClusterSwitch',
      'heatmapSwitch',
      'detailsWindowTemplate',
      'listItemTemplate',
    ];
  }

  static get outlets() {
    return ['fuse--virtual-list'];
  }

  initialize() {
    this.props = {
      map: null,
      infoWindow: null,
      markerCluster: null,
      heatmap: null,
      markers: [],
      currentMarkers: [],
    };
  }

  connect() {
    if (this.hasListTarget) {
      this.fuseVirtualListOutlet.renderer = this.renderListItem.bind(this);
    }

    this.initializeMap();
  }

  initializeMap() {
    if (!window.isMapApiInitialized(this.initializeMap.bind(this))) {
      return;
    }

    const mapOptions = {
      center: new google.maps.LatLng(...this.defaultPositionValue),
      zoom: this.defaultZoomValue,
      mapTypeId: 'roadmap',
      mapTypeControl: !this.hideControlsValue,
      zoomControl: !this.hideControlsValue,
      scaleControl: !this.hideControlsValue,
      rotateControl: !this.hideControlsValue,
      streetViewControl: false,
      fullscreenControl: false,
      styles: mapStyle,
      clickableIcons: false,
      language: window?.gon?.locale || 'en',
      restriction: {
        latLngBounds: {
          north: 85,
          south: -85,
          west: -180,
          east: 180,
        },
      },
    };
    this.map = new google.maps.Map(this.mapTarget, mapOptions);

    const markerClusterOptions = {
      map: this.map,
      markers: [],
    };

    if (this.maskMarkerClusterValue) {
      markerClusterOptions.renderer = {
        render: ({ count, position }) => {
          const marker = new google.maps.Marker({
            position,
            title: `Cluster of ${count} markers`,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          });

          this.setMarkerIcon(marker);

          return marker;
        },
      };
    }

    this.markerCluster = new MarkerClusterer(markerClusterOptions);

    const heatmapOptions = {
      map: this.map,
      data: [],
      dissipating: false,
    };
    this.heatmap = new google.maps.visualization.HeatmapLayer(heatmapOptions);

    if (this.hasDetailsWindowTemplateTarget) {
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindow.addListener('closeclick', () => {
        this.hideInfoWindow({ triggerClose: false });
      });
    }

    this.map.addListener('idle', this.handleIdleMapAfterChange.bind(this));

    if (this.hasActionsListTarget) {
      this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.actionsListTarget);
      this.actionsListTarget.hidden = false;
    }

    this.initializeData();
  }

  initializeMarker(data) {
    const latLng = new google.maps.LatLng(...data.coordinates);
    const markerOptions = {
      draggable: false,
      position: latLng,
      title: data.name,
      visible: true,
    };
    const markerIndex = this.markers.length;
    const marker = new google.maps.Marker(markerOptions);

    marker.dataIndex = data.index;
    this.setMarkerIcon(marker);

    if (data.radius) {
      const circleOptions = {
        strokeColor: '#2BCDA8',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#2BCDA8',
        fillOpacity: 0.25,
        editable: false,
        visible: false,
        map: this.map,
        radius: data.radius,
        center: latLng,
      };
      const radiusCircle = new google.maps.Circle(circleOptions);

      marker.radiusCircle = radiusCircle;

      radiusCircle.addListener('click', () => {
        this.markerClicked(data);
      });
    }

    this.markers.push(marker);

    marker.addListener('click', () => {
      this.markerClicked(data);
    });

    return markerIndex;
  }

  initializeData() {
    this.dataValue = this.dataValue.map((dataPoint, index) => {
      dataPoint.index = index;
      dataPoint.markerIndex = this.initializeMarker(dataPoint);

      return dataPoint;
    });

    if (this.hasListTarget) {
      this.fuseVirtualListOutlet.data = this.dataValue;
    }
  }

  setMarkerIcon(marker) {
    marker.setIcon({
      url: MARKER,
      scaledSize: new google.maps.Size(29, 36),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14.5, 36),
    });
  }

  handleIdleMapAfterChange() {
    const changedMarkers = {
      remove: [],
      add: [],
    };
    const currentMarkersPositions = [];
    const bounds = this.map.getBounds();

    if (this.infoWindow) {
      const infoWindowPosition = this.infoWindow.getPosition();

      if (infoWindowPosition && !bounds.contains(infoWindowPosition)) {
        this.hideInfoWindow();
      }
    }

    this.currentMarkers = this.markers.filter((marker) => {
      const position = marker.getPosition();
      const inBounds = bounds.contains(position);

      if (inBounds) {
        currentMarkersPositions.push(position);

        if (!this.currentMarkers.includes(marker)) {
          changedMarkers.add.push(marker);
        }
      } else if (this.currentMarkers.includes(marker)) {
        changedMarkers.remove.push(marker);
      }

      return inBounds;
    });

    if (this.markerClusterSwitchTarget.checked) {
      this.markerCluster.removeMarkers(changedMarkers.remove);
      this.markerCluster.addMarkers(changedMarkers.add);
    } else {
      for (const marker of changedMarkers.remove) {
        marker.setMap(null);
      }

      for (const marker of changedMarkers.add) {
        marker.setMap(this.map);
      }
    }

    if (this.heatmapSwitchTarget.checked) {
      this.heatmap.setData(currentMarkersPositions);
    }
  }

  toggleSettings() {
    this.settingsTarget.hidden = !this.settingsTarget.hidden;
  }

  toggleList() {
    this.listTarget.hidden = !this.listTarget.hidden;

    if (!this.listTarget.hidden && this.hasFuseVirtualListOutlet) {
      this.fuseVirtualListOutlet.indexesToRender = this.currentMarkers.map((marker) => marker.dataIndex);
    }
  }

  toggleMarkersFromEvent() {
    this.toggleMarkers();
  }

  toggleMarkers(display = this.markersSwitchTarget.checked) {
    if (this.markersSwitchTarget.checked !== display) {
      this.markersSwitchTarget.checked = display;
    }

    for (const marker of this.markers) {
      marker.setVisible(display);
    }

    if (!display) {
      this.hideInfoWindow();
    }
  }

  toggleHeatmapFromEvent() {
    this.toggleHeatmap();
  }

  toggleHeatmap(display = this.heatmapSwitchTarget.checked) {
    if (this.heatmapSwitchTarget.checked !== display) {
      this.heatmapSwitchTarget.checked = display;
    }

    if (display) {
      this.heatmap.setData(this.heatmapData);
      return;
    }

    this.heatmap.setData([]);
  }

  toggleMarkerClusterFromEvent() {
    this.toggleMarkerCluster();
  }

  toggleMarkerCluster(display = this.markerClusterSwitchTarget.checked) {
    if (this.markerClusterSwitchTarget.checked !== display) {
      this.markerClusterSwitchTarget.checked = display;
    }

    if (display) {
      this.markerCluster.addMarkers(this.currentMarkers);
      return;
    }

    this.markerCluster.clearMarkers();

    for (const marker of this.currentMarkers) {
      marker.setMap(this.map);
    }
  }

  zoomToInfoWindowFromEvent({ currentTarget }) {
    const index = Number(currentTarget.dataset.index);
    const data = this.dataValue[index];
    const marker = this.markers[data.markerIndex];

    this.map.setOptions({
      center: marker.getPosition(),
      zoom: 13,
    });

    this.hideInfoWindow();
  }

  showInfoWindowFromEvent({ currentTarget }) {
    const index = Number(currentTarget.dataset.index);
    const data = this.dataValue[index];

    this.showInfoWindow(data, { onlyWhenOpened: true });
  }

  hideInfoWindowFromEvent({ currentTarget }) {
    if (!this.infoWindow) return;

    const index = Number(currentTarget.dataset.index);
    const data = this.dataValue[index];
    const marker = this.markers[data.markerIndex];

    marker.radiusCircle?.setVisible(false);

    if (this.infoWindow.originalData) {
      this.showInfoWindow(this.infoWindow.originalData);
    }
  }

  fitToAllPoints() {
    const bounds = new google.maps.LatLngBounds();

    for (const marker of this.markers) {
      bounds.extend(marker.getPosition());
    }

    this.map.fitBounds(bounds);
  }

  showInfoWindow(data, { onlyWhenOpened = false } = {}) {
    if (!this.infoWindow) return;
    if (!this.hasDetailsWindowTemplateTarget) return;

    const marker = this.markers[data.markerIndex];
    const infoContent = this.filledTemplateWithData(
      this.detailsWindowTemplateTarget.innerHTML,
      JSON.parse(this.detailsWindowTemplateTarget.dataset.params),
      data,
    );

    this.infoWindow.setContent(infoContent);

    if (this.infoWindow.originalData) {
      this.markers[this.infoWindow.originalData.markerIndex].radiusCircle?.setVisible(false);
    }

    if (onlyWhenOpened) {
      if (this.infoWindow.originalData) {
        this.infoWindow.setPosition(marker.getPosition());
        marker.radiusCircle?.setVisible(true);
      }
    } else {
      this.infoWindow.open({ anchor: marker, map: this.map });
      this.infoWindow.originalData = data;
      marker.radiusCircle?.setVisible(true);
    }
  }

  hideInfoWindow({ triggerClose = true } = {}) {
    if (!this.infoWindow) return;

    if (this.infoWindow.originalData) {
      this.markers[this.infoWindow.originalData.markerIndex].radiusCircle?.setVisible(false);
      this.infoWindow.originalData = undefined;
    }

    if (triggerClose) {
      this.infoWindow.close();
    }
  }

  renderListItem(data) {
    if (!this.hasListItemTemplateTarget) return '';

    return this.filledTemplateWithData(
      this.listItemTemplateTarget.innerHTML,
      JSON.parse(this.listItemTemplateTarget.dataset.params),
      data,
    );
  }

  markerClicked(data) {
    if (!this.infoWindow) return;

    if (this.infoWindow.originalData && data.index === this.infoWindow.originalData.index) {
      this.hideInfoWindow();
      return;
    }

    this.showInfoWindow(data);
  }

  filledTemplateWithData(template, params, data) {
    let html = template;

    for (const param of params) {
      const placeholder = `{${toUnderscore(param).toUpperCase()}}`;
      const value = data[param] ? data[param] : '[unknown]';

      html = html.replace(new RegExp(placeholder, 'g'), value);
    }

    html = html.replace(/{INDEX}/g, data.index);

    return html;
  }

  get heatmapData() {
    const data = [];

    for (const marker of this.currentMarkers) {
      const position = marker.getPosition();

      if (this.map.getBounds().contains(position)) {
        data.push(position);
      }
    }

    return data;
  }

  get map() {
    return this.props.map;
  }

  set map(map) {
    this.props.map = map;
  }

  get infoWindow() {
    return this.props.infoWindow;
  }

  set infoWindow(infoWindow) {
    this.props.infoWindow = infoWindow;
  }

  get markerCluster() {
    return this.props.markerCluster;
  }

  set markerCluster(markerCluster) {
    this.props.markerCluster = markerCluster;
  }

  get heatmap() {
    return this.props.heatmap;
  }

  set heatmap(heatmap) {
    this.props.heatmap = heatmap;
  }

  get markers() {
    return this.props.markers;
  }

  set markers(markers) {
    this.props.markers = markers;
  }

  get currentMarkers() {
    return this.props.currentMarkers;
  }

  set currentMarkers(currentMarkers) {
    this.props.currentMarkers = currentMarkers;

    if (this.hasListTarget && !this.listTarget.hidden) {
      this.fuseVirtualListOutlet.indexesToRender = this.currentMarkers.map((marker) => marker.dataIndex);
    }
  }
}
