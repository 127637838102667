import { remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    this.dispatchOnWindow('open', { prefix: 'modal', detail: { name: this.modalName } });

    remove(this.element);
  }

  get modalName() {
    return this.element.dataset.modalName;
  }
}
